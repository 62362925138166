@import "../../style/colors";
.AdminReferralSystemItem {
  display: flex;
  justify-content: space-between;

  width: calc(100% - 32px);

  box-shadow: 0 20px 80px rgba(18, 18, 35, 0.08);
  border-radius: 8px;
  background-color: $white;

  padding: 20px 32px 20px 48px;

  .head {
    min-width: 100px;
    margin: auto 0 auto 0;
    padding-right: 3%;

    .title {
      font-size: 20px;
      line-height: 120%;
      font-weight: 800;
    }
  }

  .buyer {
    min-width: 10% !important;
  }

  .amount {
    min-width: 2% !important;
  }

  .body_item {
    display: flex;
    flex-direction: column;
    justify-content: center;

    min-width: 9%;

    font-size: 14px;
    line-height: 24px;
    color: $secondary;

    .label {
      font-weight: 400;
      color: $secondary;
      opacity: 0.5;
      white-space: nowrap;
    }

    .statusLabel {
      margin-left: 4px;
    }

    .date {
      font-weight: 800;
      white-space: nowrap;
    }

    .price2 {
      color: $secondary;
      white-space: nowrap;
      display: flex;
      align-items: center;
      margin: 0;

      img {
        height: 12px;
        margin-right: 4px;
      }
    }

    .status {
      white-space: nowrap;
      display: flex;
      align-items: center;
      margin: 0;

      img {
        margin-right: 4px;
      }
    }

    .price2, .status {
      font-weight: 800;
    }

    .paid {
      color: $primary;
    }

    .pending {
      color: $secondary;
      opacity: 0.5;
    }
  }

  .footer {
    display: flex;

    .img_container{
      width: 80px;
      height: 80px;
      text-align: center;
      align-self: center;

      img {
        max-width: 80px;
        max-height: 80px;

        border-radius: 8px;
      }
    }

    img {
      max-width: 80.81px;
      max-height: 80px;

      border-radius: 8px;
    }
  }
}

.AdminReferralSystemItem:not(:last-child) {
  margin-bottom: 20px;
}

.nftLink {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
}