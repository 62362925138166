@import "../../style/colors";

.root {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1000;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paper {
  width: calc(100% - 80px);
  max-width: 600px;
  padding: 40px;
  border-radius: 10px;
  box-sizing: border-box;
  background-color: $white;
  outline: none;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
  color: #3D3D3D;
}

.title {
  font-weight: 900;
  font-size: 28px;
  text-align: center;
  margin: 0 0 40px;
}

.formGroup {
  margin-bottom: 25px;
  position: relative;
}

.formLabel {
  margin: 0 0 8px;
  font-size: 18px;
  color: #3D3D3D;
}

.formInput {
  width: 100%;
  outline: none;
  height: 50px;
  border-radius: 10px;
  border: 1px solid #EAEAF1;
  padding: 8px 16px;
  box-sizing: border-box;
  font-size: 16px;
}

.longInput {
  resize: none;
  height: 100px;
  margin: 0;
}

.lengthIndicator {
  margin-top: 6px;
  margin-right: 6px;
  text-align: right;
  color: rgba(0, 0, 0, .6);

  & + .error {
    margin-top: -20px;
  }
}

.hasError {
  border: 1px solid rgb(235, 87, 87);
}

.error {
  margin: 6px 0 0 6px;
  color: rgb(235, 87, 87);
}

.footer {
  display: flex;
  justify-content: center;
}

.button {
  width: 168px;
  height: 48px;
  border-radius: 8px;
  font-weight: 700;
  font-size: 18px;
  background-color: $primary;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.save {
  background-color: $primary;
  color: $white;
  margin-right: 40px
};

.cancel {
  background-color: $white;
  border: 1px solid $primary;
  color: $primary;
}

.avatarBox {
  position: relative;
  width: 100px;
  height: 100px;
  background-color: #FAFAFA;
  border-radius: 100px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.avatar {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
}

.upload {
  position: absolute;
  width: 37px;
  height: 37px;
  bottom: 4px;
  right: -4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 100%;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.uploadIcon {
  width: 24px;
  height: 24px;
  color: $primary;
}

.disabled {
  cursor: not-allowed;
  box-shadow: none;
  opacity: 0.7;
}

@media only screen and (max-width: 600px) {
  .paper {
    padding: 40px 20px;
  }

  .title {
    font-size: 20px;
  }

  .footer {
    flex-direction: column;
  }

  .button {
    width: calc(100% - 48px);
    margin-left: auto;
    margin-right: auto;
  }

  .save {
    margin-bottom: 20px;
  }
}
