@import '../../style/colors';

.modalBlur{
  filter: blur(2px);
}

.container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  padding-top: 100px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #F4F5F8;
}
.documentContainer {
  position: relative;
  width: 100%;
  overflow-y: auto;
  padding-left: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.documentLink {
  margin: 5px;
}
.linkUnderline {
  &:hover {
    text-decoration: underline;
    opacity: 0.9;
  }
}
.docsLink {
  color: dodgerblue;
}
.documentPaddings {
  padding-bottom: 10px;
}
.header {
  width: 100%;
  box-sizing: border-box;
  padding-right: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.headerButton {
  min-width: 168px;
  height: 48px;
  padding: 0 15px;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $primary;
  color: $white;
  cursor: pointer;
  font-weight: 700;
  font-size: 18px;
  user-select: none;
  margin-right: 16px;

  &:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
  }

  &:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }
}

.inner {
  width: 100%;
  max-width: 1280px;
  padding-bottom: 80px;
}

.topContainer {
  display: flex;
  flex-direction: row;
  padding: 0 40px;
}

.itemSummary {
  flex: 1 1 0;
  max-width: 500px;
}

.itemMain {
  flex: 1 1 0;
  margin-left: 40px;
  overflow: hidden;
}

.itemMedia {
  margin: 40px 0;
  overflow: hidden;
  border-radius: 8px;

  .media {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #FFFFFF;
    
    .loader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .content {
      width: 100%;
      object-fit: contain;
      border: none;
      margin: 50px 0;
    }
  }
}

.previewList {
  overflow-x: scroll;
  display: flex;
  align-items: center;
}

.preview {
  flex: 0 0 80px;
  height: 80px;
  margin: 5px 8px;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  box-sizing: border-box;
  border: 4px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    object-fit: contain;
  }

  &.active {
    border-color: $primary;

    img {
      border-radius: 0px;
    }
  }
}

.property {
  display: flex;
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 14px;

  &:last-child {
    margin-bottom: 0;
  }
}

.propertyLabel {
  font-weight: 700;
  margin-right: 12px;
  white-space: nowrap;

  &::first-letter {
    text-transform: capitalize;
  }
}

.collectionDescription {
  font-size: 14px;
}

.socialLinks {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.socialLink {
  width: 20px;
  height: 20px;
  padding: 4px;
  margin-right: 20px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #EEE;
  }

  &:last-child {
    margin-right: 0;
  }

  img {
    width: 100%;
    height: 100%;
  }
}

.panelTitle {
  display: flex;
  align-items: center;
}

.itemInfo {
  display: none;
  position: relative;
  overflow: hidden;
}

.itemInfoCont {
  margin: 40px 0;
  border-radius: 10px;
  border: 1px solid #EAEAF1;
  overflow: hidden;
}

.itemCategory {
  font-size: 16px;
  font-weight: 400;
  color: $primary;
  margin-right: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.itemName {
  color: black;
  margin-top: 16px;
  margin-right: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 110%;
}

.itemDescription {
  color: black;
  word-break: break-word;
  margin-top: 10px;
  font-family: 'Proxima Nova';
  font-style: normal;
  font-size: 20px;
  line-height: 120%;
  max-height: 5em;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: max-height 0.7s;
  text-align: justify;
}

.bestBuyNow {
  width: 129px !important;
  height: 48px !important;
  margin-top: auto !important;
}

.displayDescription {
  max-height: 500vh;
}

.itemStats {
  margin-top: 34px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #A2A2AD;
}

.itemOwner {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #A2A2AD;
}

.ownerAvatar {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;

  .avatar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.ownerName,
.owner a,
.from a,
.to a {
  text-decoration: none;
  color: $primary;
}

.itemViews {
  margin-left: 20px;
  display: flex;
  align-items: center;

  &:first-child {
    margin-left: 0;
  }

  &.clickable {
    cursor: pointer;
  }
}

.userNftStatus {
  font-weight: bold;
}

.liking {
  color: $primary;
}

.favIcon {
  width: 16px !important;
  &:hover {
    color: $primary;
  }
}

.panelBody {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.panelLine {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 14px;

  &:last-child {
    margin-bottom: 0;
  }
}

.listings,
.offers,
.items {
  margin-bottom: -1px;
}

.listing,
.offer,
.bundleItem {
  padding-left: 21px;
  height: 50px;
  box-sizing: border-box;
  border-bottom: 1px solid #EAEAF1;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: $secondary;
}

.items {
  max-height: 400px;
  overflow-y: auto;
}

.bundleItem {
  height: 80px;
  text-decoration: none;
}

.bundleItemImage {
  flex: 0 0 60px;
  height: 60px;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.bundleItemInfo {
  margin: 0 14px;
  overflow: hidden;
}

.bundleItemCategory {
  font-size: 18px;
  color: $primary;
}

.bundleItemCategory,
.bundleItemName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bundleItemSupply {
  margin-left: auto;
  margin-right: 14px;
  font-size: 18px;
}

.noOffers {
  padding-top: 40px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.noOffersLabel {
  font-weight: 400;
  font-size: 14px;
  color: #A2A2AD;
}

.makeOffer {
  margin-top: 27px;
  width: 168px;
  height: 48px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color:$primary;
  font-size: 16px;
  font-weight: 700;
  color: $white;
  cursor: pointer;
}

.heading {
  background-color: #F6F7F9;
}

.owner {
  flex: 2;
}

.owner a,
.from a,
.to a {
  width: fit-content;
  display: flex;
  align-items: center;
}

.userAvatarWrapper {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  margin-right: 6px;
}

.userAvatar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.price {
  flex: 2;
  display: flex;
  align-items: center;
}

.quantity {
  flex: 2;
}

.deadline {
  flex: 3;
}

.buy {
  flex-basis: 100px;
  padding: 5px 4px;
}

.tokenIcon {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-right: 6px;
}

.buyButton {
  min-width: 80px;
  height: 40px;
  padding: 0 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: $white;
  background-color: $primary;
  font-weight: 700;
  font-size: 18px;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;

  &:hover {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
  }
}

.bids {
  padding: 20px 16px;
}

.result {
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.bidtitle {
  font-size: 18px;
  display: flex;
  align-items: center;
}

.bidAmount {
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.placeBid,
.withdrawBid {
  min-width: 120px;
  width: fit-content;
  height: 40px;
  padding: 0 15px;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 1px;
  user-select: none;
  margin-top: 15px;

  &:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
  }
}

.placeBid {
  background-color: $primary;
  color: $white;
}

.withdrawBid {
  background-color: $white;
  color: $primary;
  border: 1px solid $primary;
}

.tradeHistoryWrapper {
  margin: 0 40px 40px;
}

.tradeHistoryHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tradeHistoryTitle {
  font-weight: 700;
  font-size: 16px;
  color: $secondary;
}

.filter {
  cursor: pointer;
}

.filterIcon {
  width: 20px;
  height: 20px;
}

.histories {
  margin-top: 40px;
  max-height: 458px;
  overflow-x: auto;
  overflow-y: auto;
}

.history {
  min-width: 600px;
  height: 58px;
  display: flex;
  align-items: center;
  padding: 0 14px;
  font-size: 14px;
  font-weight: 400;
  color: $secondary;
  border-bottom: 1px solid rgba(0, 0, 0, .1);

  &.heading {
    height: 38px;
    border-bottom: none;

    .from,
    .to {
      color: inherit;
    }
  }

  &:last-child {
    border-bottom: none;
  }
}

.from,
.to {
  flex: 3;
}

.historyPrice {
  display: flex;
  align-items: center;
  flex: 2;
}

.saleDate {
  flex: 2;
}

.loadingIndicator {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.itemsList {
  display: flex;
  overflow-x: auto;
  padding-bottom: 20px;
  margin-bottom: -20px;
}

.moreItem {
  width: 240px;
  flex: 0 0 240px;
  margin-right: 20px;
}

.itemInfoWrapper {
  margin: 40px 0;
  position: relative;
}

.itemMenu {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
}

.itemMenuBtn {
  width: 48px;
  height: 48px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 12px;
  background-color: #FFFFFF;

  &:last-child {
    border-right: 0;
  }
}

.itemMenuIcon {
  width: 17px;
  height: 17px;
  color: #A2A2AD;
}
.documentIcon {
  width: 46px;
  height: 46px;
}
.shareMenu {
  transform: translateY(55px) !important;
}

.shareMenuList {
  padding: 0 !important;
}

.menuItem {
  height: 48px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: $secondary;

  img {
    width: 20px;
    margin-right: 16px;
  }
}

.infoPanel {
  display: none;
}

.panelWrapper {
  min-width: 550px;
  margin: 20px 0;
  border-radius: 10px;
  box-sizing: border-box;
  overflow: hidden;
  background-color: #FFFFFF;
}

.adminWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;

  .statusLabel {
    text-align: end;
    margin: 0;
    margin-bottom: 8px;
  }
}

.statusDenyReason {
  font-style: italic;
  padding-left: 2%;
  padding-right: 2%;
}

.infoMessage {
  padding: 0 5% 0;
  text-align: justify;
  font-weight: 600;
  color: $primary;
  margin-bottom: 5px;
  white-space: pre-wrap;
}

.infoMessageUnderDenyMessage {
  padding-top: 2%;
}

.unlockableLabel {
  font-weight: 500;
  font-size: 18px;
  color: $secondary;
}

.unlockableContent {
  width: 100%;
  height: 100px;
  resize: none;
  margin: 20px 0 0;
  outline: none;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 8px 16px;
  box-sizing: border-box;
  font-size: 16px;
}

.bestBuy {
  padding: 14px 20px 20px;
  margin: 20px 0;
  border-radius: 10px;
  border: 1px solid #EAEAF1;
  box-sizing: border-box;
}

.currentPriceLabel {
  font-size: 18px;
  font-weight: 700;
  color: #A2A2AD;
}

.currentPriceWrapper {
  margin-top: 18px;
  display: flex;
  align-items: center;
  width: 100%;
}

.tokenLogo {
  max-width: 24px;
  width: 100%;
  height: 24px;
  margin-right: 8px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}

.currentPrice {
  font-weight: 700;
  font-size: 28px;
  color: $secondary;
  margin-right: 8px;
}

.currentPriceUSD {
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  color: #A2A2AD;
}

.escrowStatus {
  text-align: end;
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #121223;
  opacity: 0.5;
}

.adminButtons {
  display: flex;
  justify-content: space-around;
  width: 100%;

  .buttonsContainer {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
  .admin_buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 12px;
    box-sizing: border-box;
    padding: 14.5px 32px;
    max-height: 48px;
    font-size: 16px;
    line-height: 19px;
    font-weight: 700;
    color: white;
    letter-spacing: -0.2px;

    cursor: pointer;

    &:hover {
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
    }
  }

  .admin_buttons:not(:last-child) {
    margin-right: 12px;
  }

  .deny {
    width: 102px;
    background-color: $danger;
  }

  .approve {
    max-width: 173px;
    background-color: $primary;
  }
}

.revealBtn,
.buyNow {
  margin-top: 20px;
  width: 100%;
  height: 48px;
  border-radius: 10px;
  background-color: $primary;
  font-weight: 700;
  font-size: 16px;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.descriptionShow {
  width: 100%;
  height: 48px;
  border-radius: 10px;
  font-weight: 700;
  font-size: 16px;
  color: $primary;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}

.chartWrapper {
  padding: 20px;
  height: 250px;
  overflow: hidden;
  position: relative;
}

.chart {
  position: absolute;
  overflow: hidden;
}

.disabled {
  cursor: not-allowed;
  box-shadow: none !important;
  opacity: 0.7;
}

.tooltip {
  font-size: 14px;
}

.hidden {
  display: none;
}

.filtermenu {
  width: 220px;
  padding: 24px;
  border-radius: 10px !important;
  border: 1px solid rgba(0, 0, 0, .2);
  box-shadow: 0 4px 40px rgba(0, 0, 0, .15);
}

.menuList {
  padding: 0 !important;
}

.menuTitle {
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #3D3D3D;
}

.menu {
  display: flex;
  align-items: center;
  margin-top: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: rgba(0, 0, 0, .4);
  cursor: pointer;

  img {
    width: 16px;
    margin-left: 32px;
    display: none;
  }

  &.active {
    color: rgba(0, 0, 0, .6);

    img {
      display: block;
    }
  }
}

@media only screen and (max-width: 1180px) {
  .topContainer {
    flex-direction: column;
  }

  .itemSummary {
    max-width: initial;
    display: flex;
    align-items: flex-start;
  }

  .itemMedia {
    width: 280px;
  }

  .itemMain {
    margin-left: 0;
  }

  .itemInfo {
    flex: 1;
    display: block;
    margin: 40px 0 40px 40px;
    width: 100%;
  }

  .itemInfoCont {
    display: none;
  }

  .itemInfoWrapper {
    display: none;
  }

  .infoPanel {
    display: block;
  }
}

@media only screen and (max-width: 960px) {
  .itemStats {
    flex-wrap: wrap;
  }

  .itemOwner {
    flex-basis: 100%;
  }

  .itemViews {
    margin-left: 0;
    margin-top: 20px;
    margin-right: 20px;
  }
}

@media only screen and (max-width: 800px) {
  .itemSummary {
    flex-direction: column;
  }

  .itemMedia {
    width: 100%;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }

  .itemInfo {
    margin: 0;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    padding-top: 155px;
  }

  .header {
    position: relative;
    top: inherit;
    flex-direction: column;
    padding-right: 0;
  }

  .headerButton {
    margin-right: 0;
    margin-bottom: 16px;
    height: 48px;
    font-size: 16px;
    width: calc(100% - 32px);
    max-width: 300px;
  }
}

@media only screen and (max-width: 600px) {
  .topContainer {
    padding: 0 30px;
  }

  .tradeHistoryWrapper {
    margin: 0 30px 30px;
  }

  .itemStats {
    flex-direction: column;
    align-items: flex-start;
  }

  .itemOwner {
    flex-flow: wrap;
  }

  .itemOwner .itemViews {
    flex-basis: 100%;
    break-after: always;

    &:first-child {
      margin-top: 0;
    }
  }

  .itemViews {
    margin-left: 0;
    margin-top: 20px;
  }

  .panelWrapper {
    width: 100%;
    min-width: inherit;
  }

  .listings,
  .offers {
    min-width: 550px;
  }

  .moreItem {
    width: calc(100vw - 130px);
    flex-basis: calc(100vw - 130px);
  }
}

.registryLabel {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: $secondary;
  opacity: 0.5;
  padding-right: 2.5em;
}
.registryValue {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  padding-bottom: 10px;
  opacity: 1;
}

.linkLabel {
  color: $primary;
}

.attachmentLabel {
  display: flex;
  justify-content: center;
  align-items: center;
}

.status {
  color: $white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 0;
  position: absolute;
  width: 100%;
  min-height: 28px;
  left: 0px;
  bottom: 0%;

  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  z-index: 5;
  p {
    margin: 0;
    text-align: center;
  }
}
.status3, .status6, .status12 {
  background-color: $danger;
}
.status1, .status2, .status4, .status5, .status7,
.status8, .status9, .status10, .status11 {
  background-color: $primary;
}

.videoLink {
  text-decoration: underline;
  margin: 0;
  cursor: pointer;
}

.defaultAvatar {
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
  }
}

.avatar {
  position: absolute;
  top: 0;
  left: 0;
  width: 160px;
  height: 160px;
}

.forbiddenContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto auto;
  padding: 10px 10px;
  min-height: calc(100vh - 80px);
  text-align: center;
  font-weight: 700;
  font-size: 40px;
  line-height: 110%;
  letter-spacing: -0.2px;
}

.forbiddenIcon {
  margin-top: 21px;
  width: 54px;
  height: 54px;
}

.loaderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin: auto auto;
  min-height: calc(100vh - 80px);
}

.mapContainer {
  width: 500px;
  height: 318px;
  margin-top: 20px;
}

.sliderContainer {
  width: 100%;
  height: 60px;
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 10px;
  .aliceCarouselContainer {
    width: 440px;
  }
  .leftButton{
    margin-right: 4px;
  }
  .rightButton{
    margin-left: 4px;
  }
  .carouselContainer {
    width:95px;
    height: 60px;
    margin-right: 4px;
    margin-left: 4px;
    margin-right: 4px;
    margin-left: 4px;
    cursor: pointer;
    .imgStyle {
      width:95px;
      height: 60px;
    }
  }
  .sliderButtonWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width:25px;
    height: 60px;
    background: #FFFFFF;
    border-radius: 4px;
    box-sizing: border-box;
    cursor: pointer;
    .arrow {
      border: solid black;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 3px;
    }
    .arrowLeft {
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
    }
    .arrowRight {
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }
    &:hover {
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
    }
  }
  li {
    background-color: #F4F5F8;  
  }
}

.escrowFee {
  box-sizing: border-box;
  min-width: 800px;
  min-height: 410px;
  padding: 60px;
  border-radius: 8px;

  .escrowFeeLabel {
    margin: 0;
    padding-bottom: 28px;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 110%;
    color: #101828;
  }
  .escrowAmount {
    display: flex;
    width: 100%;
    border-radius: 8px;
    border: 1px solid #E3E3E3;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
    color: #121223;
    opacity: 0.5;
    margin-bottom: 20px;

    .escrowAmountInput{
      font-size: 20px;
      line-height: 120%;
      border-width: 0;
      background: #FFFFFF;
      border-radius: 8px;
      padding: 18px;
      width: 100%;
    }
    .escrowPerDay {
      text-align: center;
      padding: 17px 24px;
      background: #F4F5F8;
    }
  }

  .escrowFeePeriod {
    width: 100%;
    padding: 14px 24px 14px 24px;
    background: #F4F5F8;
    border-radius: 12px;
    margin-bottom: 40px;
    border: 0px;
    outline: 0px;
    
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    
    option {
      background: #F4F5F8;
      border: 0px;
      outline: 0px;
    }
  }

  .escrowButtons{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    .buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 55px;
      border-radius: 12px;
  
      font-size: 16px;
      line-height: 19px;
      font-weight: 700;
      color: white;
      letter-spacing: -0.2px;
      cursor: pointer;
  
      &:hover {
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
      }
    }
    .cancelEscrow {
      margin-right: 16px;
      color: $primary;
      width: 125px;
      box-shadow: none;
      background: #FFF;
    }

    .approveEscrow {
      width: 125px;
      background-color: $primary;
    }
  }
}

.pendingApprovalWaiting {
  text-align: justify;
}