@import "../../style/colors";

.modalBlur{
  filter: blur(2px);
}

.container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  padding-top: 100px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #F4F5F8;
}

.header {
  width: 100%;
  box-sizing: border-box;
  padding-right: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.headerButton {
  min-width: 168px;
  height: 48px;
  padding: 0 15px;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $primary;
  color: $white;
  cursor: pointer;
  font-weight: 700;
  font-size: 18px;
  user-select: none;
  margin-right: 16px;

  &:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
  }

  &:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }
}

.inner {
  width: 100%;
  max-width: 1280px;
  padding-bottom: 80px;
}

.topContainer {
  display: flex;
  flex-direction: row;
  padding: 0 40px;
}

.itemSummary {
  flex: 1 1 0;
  max-width: 500px;
}

.itemMain {
  flex: 1 1 0;
  margin-left: 40px;
  overflow: hidden;
}

.itemMedia {
  margin: 40px 0;
  overflow: hidden;
  border-radius: 8px;

  .media {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #FFFFFF;

    .loader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .content {
      width: 100%;
      object-fit: contain;
      border: none;
      margin: 50px 0;
    }
  }
}

.previewList {
  overflow-x: scroll;
  display: flex;
  align-items: center;
}

.preview {
  flex: 0 0 80px;
  height: 80px;
  margin: 5px 8px;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  box-sizing: border-box;
  border: 4px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    object-fit: contain;
  }

  &.active {
    border-color: $primary;

    img {
      border-radius: 0px;
    }
  }
}

.property {
  display: flex;
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 14px;

  &:last-child {
    margin-bottom: 0;
  }
}

.propertyLabel {
  font-weight: 700;
  margin-right: 12px;
  white-space: nowrap;

  &::first-letter {
    text-transform: capitalize;
  }
}

.collectionDescription {
  font-size: 14px;
}

.socialLinks {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.socialLink {
  width: 20px;
  height: 20px;
  padding: 4px;
  margin-right: 20px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #EEE;
  }

  &:last-child {
    margin-right: 0;
  }

  img {
    width: 100%;
    height: 100%;
  }
}

.panelTitle {
  display: flex;
  align-items: center;
}

.itemInfo {
  display: none;
  position: relative;
  overflow: hidden;
}

.itemInfoCont {
  margin: 40px 0;
  border-radius: 10px;
  border: 1px solid #EAEAF1;
  overflow: hidden;
}

.itemCategory {
  font-size: 16px;
  font-weight: 400;
  color: $primary;
  margin-right: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.itemName {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 110%;
  color: black;
  margin-top: 16px;
  margin-right: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.itemDescription {
  color: black;
  word-break: break-word;
  margin-top: 10px;
  font-family: 'Proxima Nova';
  font-style: normal;
  font-size: 20px;
  line-height: 120%;
  max-height: 5em;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: max-height 0.7s;
  text-align: justify;
}

.displayDescription {
  max-height: 500vh;
}

.itemStats {
  margin-top: 34px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #A2A2AD;
}

.itemOwner {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #A2A2AD;
}

.ownerAvatar {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;

  .avatar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.ownerName,
.owner a,
.from a,
.to a {
  text-decoration: none;
  color: $primary;
}

.itemViews {
  margin-left: 20px;
  display: flex;
  align-items: center;

  &:first-child {
    margin-left: 0;
  }

  &.clickable {
    cursor: pointer;
  }
}

.userNftStatus {
  font-weight: bold;
}

.liking {
  color: $primary;
}

.favIcon {
  width: 16px !important;
  &:hover {
    color: $primary;
  }
}

.panelBody {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.panelLine {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 14px;

  &:last-child {
    margin-bottom: 0;
  }
}

.listings,
.offers,
.items {
  margin-bottom: -1px;
}

.listing,
.offer,
.bundleItem {
  padding-left: 21px;
  height: 50px;
  box-sizing: border-box;
  border-bottom: 1px solid #EAEAF1;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: $secondary;
}

.items {
  max-height: 400px;
  overflow-y: auto;
}

.bundleItem {
  height: 80px;
  text-decoration: none;
}

.bundleItemImage {
  flex: 0 0 60px;
  height: 60px;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.bundleItemInfo {
  margin: 0 14px;
  overflow: hidden;
}

.bundleItemCategory {
  font-size: 18px;
  color: $primary;
}

.bundleItemCategory,
.bundleItemName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bundleItemSupply {
  margin-left: auto;
  margin-right: 14px;
  font-size: 18px;
}

.noOffers {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.noOffersLabel {
  font-weight: 400;
  font-size: 14px;
  color: #A2A2AD;
}

.noListings {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.noListingsLabel {
  font-weight: 400;
  font-size: 14px;
  color: #A2A2AD;
}

.listingButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}

.listingButton {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 0 !important;

  &:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
  }
}

.cancelListing {
  background: $danger !important;
}

.makeOffer {
  width: 168px;
  height: 48px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $primary;
  font-size: 16px;
  font-weight: 700;
  color: $white;
  cursor: pointer;
  transition: box-shadow 0.3s;

  &:hover {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.8);
  }
}

.startAuctionBtn {
  width: 168px;
  height: 48px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  font-size: 16px;
  font-weight: 700;
  color: $primary;
  border: 1px solid $primary;
  cursor: pointer;
  transition: box-shadow 0.3s;
  box-sizing: border-box;

  &:hover {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
  }
}

.stakeNft {
  width: 140px;
  height: 48px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #66ff8f;
  font-size: 16px;
  font-weight: 700;
  color: black;
  cursor: pointer;
}

.heading {
  border: none;
}

.owner {
  flex: 2;
}

.owner a,
.from a,
.to a {
  width: fit-content;
  display: flex;
  align-items: center;
}

.userAvatarWrapper {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  margin-right: 6px;
}

.userAvatar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.price {
  flex: 2;
  display: flex;
  align-items: center;
}

.quantity {
  flex: 2;
}

.deadline {
  flex: 3;
}

.buy {
  flex-basis: 100px;
  padding: 5px 4px;
}

.tokenIcon {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-right: 6px;
}

.buyButton {
  min-width: 80px;
  height: 40px;
  padding: 0 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: $white;
  background-color: $primary;
  font-weight: 700;
  font-size: 18px;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;

  &:hover {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
  }
}

.bids {
  padding: 20px 16px;
}

.result {
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.bidtitle {
  font-size: 18px;
  display: flex;
  align-items: center;
}

.bidAmount {
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.placeBid,
.withdrawBid {
  min-width: 120px;
  width: fit-content;
  height: 40px;
  padding: 0 15px;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 1px;
  user-select: none;
  margin-top: 15px;

  &:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
  }
}

.placeBid {
  background-color: $primary;
  color: $white;
}

.withdrawBid {
  background-color: $white;
  color: $primary;
  border: 1px solid $primary;
}

.tradeHistoryWrapper {
  margin: 0 40px 40px;
}

.tradeHistoryHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px 10px 0 0;
  background-color: #FFFFFF;
  box-shadow: inset 0px -1px 0px #E3E3E3;
  padding: 12px 24px;
}

.tradeLabel {
  display: flex;
}

.tradeHistoryTitle {
  font-weight: 700;
  font-size: 16px;
  color: $secondary;
}

.filter {
  cursor: pointer;
}

.filterIcon {
  width: 20px;
  height: 20px;
}

.tradeIcon {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}

.histories {
  max-height: 458px;
  overflow-x: auto;
  overflow-y: auto;
  border-radius: 0 0 10px 10px;
  background-color: #FFFFFF;
  box-shadow: inset 0px -1px 0px #E3E3E3;
  padding: 12px 24px;
}

.history {
  min-width: 600px;
  height: 58px;
  display: flex;
  align-items: center;
  padding: 0 14px;
  font-size: 14px;
  font-weight: 400;
  color: #A2A2AD;
  background-color: inherit;
  border-bottom: 1px solid rgba(0, 0, 0, .1);

  &.heading {
    height: 38px;
    border-bottom: none;

    .from,
    .to {
      color: inherit;
    }
  }

  &:last-child {
    border-bottom: none;
  }
}

.from,
.to {
  flex: 3;
}

.historyPrice {
  display: flex;
  align-items: center;
  flex: 2;
}

.saleDate {
  flex: 2;
}

.loadingIndicator {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.itemsList {
  display: flex;
  overflow-x: auto;
  padding-bottom: 20px;
  margin-bottom: -20px;
}

.moreItem {
  width: 240px;
  flex: 0 0 240px;
  margin-right: 20px;
}

.itemInfoWrapper {
  margin: 40px 0;
  position: relative;
}

.itemMenu {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;

  :not(:last-child) {
    margin-right: 10px;
  }
}

.itemMenuBtn {
  width: 48px;
  height: 48px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 12px;
  background-color: #FFFFFF;
}

.itemMenuIcon {
  width: 17px;
  height: 17px;
  color: $secondary;
}

.itemMenuIconBurn {
  width: 21px;
  height: 21px;
}

.shareMenu {
  transform: translateY(55px) !important;
}

.shareMenuList {
  padding: 0 !important;
}

.menuItem {
  height: 48px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: $secondary;

  img {
    width: 20px;
    margin-right: 16px;
  }
}

.infoPanel {
  display: none;
}

.buttonsBlock {
  min-width: 550px;
  margin: 20px 0;
  border-radius: 10px;
  box-sizing: border-box;
  overflow: hidden;
  background-color: #FFFFFF;
}

.buttonsBlockInner {
  width: 100%;
  padding: 12px 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  :not(:last-child) {
    margin-right: 15px;
  }
}

.panelWrapper {
  min-width: 550px;
  margin: 20px 0;
  border-radius: 10px;
  box-sizing: border-box;
  overflow: hidden;
  background-color: #FFFFFF;
}

.topWrapper {
  border-radius: 10px 10px 0 0;
  box-shadow: inset 0px -1px 0px #E3E3E3;
  box-sizing: border-box;
  overflow: hidden;
  background-color: #FFFFFF;
}
.middleWrapper {
  border-radius: 0 0 0 0;
  box-shadow: inset 0px -1px 0px #E3E3E3;
  box-sizing: border-box;
  overflow: hidden;
  background-color: #FFFFFF;
}
.bottomWrapper {
  border-radius: 0 0 10px 10px;
  box-sizing: border-box;
  overflow: hidden;
  background-color: #FFFFFF;
}

.adminWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.statusDenyReason {
  font-style: italic;
  padding-left: 2%;
  padding-right: 2%;
}

.unlockableLabel {
  font-weight: 500;
  font-size: 18px;
  color: $secondary;
}

.unlockableContent {
  width: 100%;
  height: 100px;
  resize: none;
  margin: 20px 0 0;
  outline: none;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 8px 16px;
  box-sizing: border-box;
  font-size: 16px;
}

.bestBuy {
  padding: 14px 20px 20px;
  margin: 20px 0;
  border-radius: 10px;
  box-sizing: border-box;
  background: $white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.priceOffer {
  justify-content: space-between;
  align-items: end;

}
.currentPriceLabel {
  font-size: 20px;
  font-weight: 700;
  line-height: 120%;
  color: $secondary;
  margin-right: 10px;
}
.buyOfferBtn {
  width: 25% !important;
}
.currentPriceWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.currentPriceValue {
  display: flex;
  align-items: center;
}

.tokenLogo {
  max-width: 24px;
  width: 100%;
  height: 24px;
  margin-right: 8px;
  align-self: flex-end;
  padding-bottom: 8px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}

.currentPrice {
  font-weight: 800;
  font-size: 32px;
  line-height: 120%;
  color: $secondary;
  margin-right: 4px;
  height: 38px;
  margin-top: auto;
}

.currentPriceUSD {
  width: 100%;
  height: 38px;
  font-weight: 400;
  font-size: 14px;
  color: $secondary;
  opacity: 0.5;
  display: flex;
  flex-direction: column-reverse;
  padding-bottom: 7px;
}

.adminButtons {
  display: flex;
  justify-content: space-around;
  width: 100%;

  .admin_buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 12px;
    padding: 14.5px 32px;
    margin: 1em;

    font-size: 16px;
    line-height: 119%;
    font-weight: 700;
    color: white;
    letter-spacing: -0.2px;

    cursor: pointer;

    &:hover {
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
    }
  }

  .admin_buttons:not(:last-child) {
    margin-right: 30px;
  }

  .deny {
    max-width: 50px;
    background-color: $danger;
  }

  .approve {
    max-width: 60px;
    background-color: $primary;
  }
}

.revealBtn,
.buyNow {
  margin-top: 20px;
  width: 100%;
  height: 48px;
  border-radius: 10px;
  background-color: $primary;
  font-weight: 700;
  font-size: 16px;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.8);
  }
}

.descriptionShow {
  width: 100%;
  height: 48px;
  border-radius: 10px;
  font-weight: 700;
  font-size: 16px;
  color: $primary;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}

.chartWrapper {
  padding: 20px;
  height: 250px;
  overflow: hidden;
  position: relative;
}

.chart {
  position: absolute;
  overflow: hidden;
}

.disabled {
  cursor: not-allowed;
  box-shadow: none !important;
  opacity: 0.7;
}

.tooltip {
  font-size: 14px;
}

.hidden {
  display: none;
}

.filtermenu {
  width: 220px;
  padding: 24px;
  border-radius: 10px !important;
  border: 1px solid rgba(0, 0, 0, .2);
  box-shadow: 0 4px 40px rgba(0, 0, 0, .15);
}

.menuList {
  padding: 0 !important;
}

.menuTitle {
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #3D3D3D;
}

.menu {
  display: flex;
  align-items: center;
  margin-top: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: rgba(0, 0, 0, .4);
  cursor: pointer;

  img {
    width: 16px;
    margin-left: 32px;
    display: none;
  }

  &.active {
    color: rgba(0, 0, 0, .6);

    img {
      display: block;
    }
  }
}

@media only screen and (max-width: 1180px) {
  .topContainer {
    flex-direction: column;
  }

  .itemSummary {
    max-width: initial;
    display: flex;
    align-items: flex-start;
  }

  .itemMedia {
    width: 280px;
  }

  .itemMain {
    margin-left: 0;
  }

  .itemInfo {
    flex: 1;
    display: block;
    margin: 40px 0 40px 40px;
    width: 100%;
  }

  .itemInfoCont {
    display: none;
  }

  .itemInfoWrapper {
    display: none;
  }

  .infoPanel {
    display: block;
  }
}

@media only screen and (max-width: 960px) {
  .itemStats {
    flex-wrap: wrap;
  }

  .itemOwner {
    flex-basis: 100%;
  }

  .itemViews {
    margin-left: 0;
    margin-top: 20px;
    margin-right: 20px;
  }
}

@media only screen and (max-width: 800px) {
  .itemSummary {
    flex-direction: column;
  }

  .itemMedia {
    width: 100%;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }

  .itemInfo {
    margin: 0;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    padding-top: 155px;
  }

  .header {
    position: relative;
    top: inherit;
    flex-direction: column;
    padding-right: 0;
  }

  .headerButton {
    margin-right: 0;
    margin-bottom: 16px;
    height: 48px;
    font-size: 16px;
    width: calc(100% - 32px);
    max-width: 300px;
  }
}

@media only screen and (max-width: 600px) {
  .topContainer {
    padding: 0 30px;
  }

  .tradeHistoryWrapper {
    margin: 0 30px 30px;
  }

  .itemStats {
    flex-direction: column;
    align-items: flex-start;
  }

  .itemOwner {
    flex-flow: wrap;
  }

  .itemOwner .itemViews {
    flex-basis: 100%;
    break-after: always;

    &:first-child {
      margin-top: 0;
    }
  }

  .itemViews {
    margin-left: 0;
    margin-top: 20px;
  }

  .panelWrapper {
    width: 100%;
    min-width: inherit;
  }

  .listings,
  .offers {
    min-width: 550px;
  }

  .moreItem {
    width: calc(100vw - 130px);
    flex-basis: calc(100vw - 130px);
  }
}

.attachmentLabel {
  display: flex;
  justify-content: center;
  align-items: center;
}

.documentLink {
  margin: 5px;
}

.documentIcon {
  width: 46px;
  height: 46px;
}

.documentContainer {
  position: relative;
  width: 100%;
  overflow-y: auto;
  padding-left: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.registryLabel {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: $secondary;
  opacity: 0.5;
  padding-right: 2.5em;
}

.registryValue {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  padding-bottom: 10px;
  opacity: 1;
}

.linkLabel {
  color: $primary;
}

.videoLink {
  text-decoration: underline;
  margin: 0;
  cursor: pointer;
}

.mapContainer {
  width: 500px;
  height: 318px;
}

.sliderContainer {
  width: 100%;
  height: 60px;
  display: flex;
  flex-wrap: nowrap;
  .aliceCarouselContainer {
    width: 440px;
  }
  .leftButton{
    margin-right: 4px;
  }
  .rightButton{
    margin-left: 4px;
  }
  .sliderimg {
    width:95px;
    height: 60px;
    margin-right: 4px;
    margin-left: 4px;
    margin-right: 4px;
    margin-left: 4px;
    cursor: pointer;
  }
  .sliderButtonWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width:25px;
    height: 60px;
    background: #FFFFFF;
    border-radius: 4px;
    box-sizing: border-box;
    cursor: pointer;
    .arrow {
      border: solid black;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 3px;
    }
    .arrowLeft {
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
    }
    .arrowRight {
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }
    &:hover {
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
    }
  }
  li {
    background-color: #F4F5F8;  
  }
}