.MuiAppBar-colorPrimary {
  color: #fff;
  background-color: #7F56D9 !important;
}

.css-gr2aqs {
  background-color: #7F56D9 !important;
}

body {
  margin: 0 !important;
  overflow: auto;
}
.slick-slide {
  outline: none;
  width: unset !important;
  /* width: 360px !important; */
}

body * {
  font-family: 'proxima-nova' !important;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.MuiPagination-root > .MuiPagination-ul > li > .MuiPaginationItem-root {
  font-weight: 700;
}

.MuiPagination-root > .MuiPagination-ul {
  justify-content: center;
}