@import "../../style/colors";

.container {
  padding-top: 80px;
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  overflow-y: auto;
}

.sidebar {
  flex: 0 0 300px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  background: $grey-100;
}

.collapsed {
  flex: 0 0 64px;

  .sidebarHeader {
    padding: 0;
    justify-content: center;
  }

  .iconCollapse {
    transform: rotateZ(180deg);
  }

  .filterList {
    display: none;
  }
}

.sidebarHeader {
  height: 88px;
  flex: 0 0 88px;
  display: flex;
  padding: 0 32px 0 24px;
  align-items: center;
  justify-content: space-between;
}

.sidebarTitle {
  font-size: 28px;
  font-weight: 800;
  color: $secondary;
  line-height: 110%;
}

.iconCollapse {
  cursor: pointer;
  transform: rotateZ(0deg);
  transition: transform ease 300ms;
}

.profileWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.avatar {
  width: 100px !important;
  height: 100px !important;
  border-radius: 50px;
}

.username {
  margin-top: 8px;
  font-size: 24px;
  font-weight: 600;
  color: #333;
}

.address {
  margin-top: 8px;
  font-size: 18px;
  font-weight: 500;
  color: #333;
}

.bio {
  margin-top: 8px;
  font-size: 16px;
  font-weight: 400;
  color: #555;
  text-align: center;
}

.body {
  margin-left: 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
}

.exploreAll {
  height: fit-content;
  max-height: 92%;
  overflow-y: scroll;
  margin-left: -40px;
  padding-left: 40px;
  padding-right: 10px;
  padding-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
}

.filterHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 48px 0 16px 12px;
}

@media only screen and (max-width: 768px) {
  .container {
    padding-top: 133px;
  }

  .sidebar:not(.collapsed) {
    flex: 0 0 260px;
  }
}

@media only screen and (max-width: 600px) {
  .container {
    flex-direction: column;

    /* width */
    &::-webkit-scrollbar {
      width: 0;
    }
  }

  .sidebar {
    flex-basis: unset;
    flex-grow: 1;
  }

  .collapsed {
    flex-basis: unset;
    flex-grow: 1;

    .filterList {
      display: block;
    }
  }

  .sidebarHeader {
    display: none;
  }

  .filterHeader {
    padding-left: 24px;
    padding-right: 24px;
  }

  .body {
    margin-left: 0;
    overflow-y: visible;
  }

  .exploreAll {
    padding-right: 0;
    overflow-y: visible;
  }
}
