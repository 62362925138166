@import "../../style/colors";

$scaleFactor: 1.5;
$authAnimationLength: 0.5s;

.outerContainer {
  display: flex;
  flex-direction: column;
}

.authContainer {
  width: 100%;
  min-height: 1000px;
  max-height: 100vh;
  height: 100vh;
  padding-top: 80px;
  padding-bottom: 48px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  backdrop-filter: blur(17px);
  .inner560 {
    width: 560px;
  }
  .inner600 {
    width: 600px;
  }
  .inner650 {
    width: 650px;
  }
  .inner700 {
    width: 700px;
  }
  .inner {
    width: 690px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 55px;
    box-sizing: border-box;
    background-color: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(17px);
    border-radius: 20px;
    height: 100%;

    .authForm {
      height: 446px;
      padding: 60px 60px 70px;
      position: relative;
      border: 1.5px solid rgba(255, 255, 255, 0.2);
      box-shadow: 32.2748px 50.026px 188.808px #F3F4F6;
      border-radius: 20px;
      background: rgba(255, 255, 255, 0.4);

      animation: slideFromBottom $authAnimationLength forwards;
      -webkit-animation: slideFromBottom $authAnimationLength forwards;

      @keyframes slideFromBottom {
        from {
          transform: translate(0, 250px) scale(0.9, $scaleFactor);

          opacity: 0;
        }

        to {
          transform: translate(0, 0) scale(1, 1);

          opacity: 1;
        }
      }

      .closeButton {
        position: absolute;
        right: 30px;
        top: 30px;
        width: 12px;
        height: 12px;
        opacity: 0.4;
        cursor: pointer;
      }
      .title {
        font-size: 60px;
        font-family: inherit;
        font-weight: 800;
        line-height: 110%;
        color: $secondary;
        text-align: center;
        padding-bottom: 48px;

        animation: slide $authAnimationLength forwards;
        -webkit-animation: slide $authAnimationLength forwards;

        @keyframes slide {
          from {
            transform: translate(0, 60px) scale(1, 0.666 /*1/$scaleFactor*/);

            opacity: 0;
          }

          to {
            transform: translate(0, 24px) scale(1, 1);

            opacity: 1;
          }
        }
      }
      .subTitle {
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 120%;
        text-align: center;
        color: #121223;
        opacity: 0.4;
      }

      .codeInputContainer {
        display: flex;
        justify-content: center;
      }
      .codeInput {
        text-align: center;
        margin: 6px;
        input {
          text-align: center;
          margin: 6px;
        }
        input:nth-child(3) {
          margin-right: 26px;
        }
      }

      .inputGroup {
        margin-top: 16px;
        padding-left: 12px;
        padding-right: 12px;
        .inputWrapper {
          margin-top: 16px;
          .input{
            width: 100%;
            height: 60px;
            padding: 8px 16px;
            box-sizing: border-box;
            font-size: 20px;
            color: $secondary;
            border-radius: 12px;
            border: none;
            outline: none;
            border: 0;
            background-color: $grey-100;
            input {
              text-align: center !important;
              background-color: $grey-100;
              color: $secondary;
              height: 21px;
              &:-webkit-autofill{
                box-shadow: 0 0 0 30px $grey-100 inset !important;
                -webkit-box-shadow: 0 0 0 30px $grey-100 inset !important;
                font-size: inherit;
                -webkit-text-fill-color: inherit !important;
              }
            }

            &.password input {
              padding-left: 48px;
            }

            fieldset {
              border: 0;
              border-radius: 12px;
            }
            &.hasError {
              border: 1px solid $red;
            }
          }
        }
      }
      .error {
        color: $red;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
      }
      .formLabelRow {
        padding-top: 16px;
        display: flex;
        gap: 16px;
        line-height: 32px;
      }
      .formLabelRowCenterredGray {
        padding-top: 12px;
        font-size: 14px;
        font-weight: 400;
        color: rgba(18, 18, 35, 0.4);
        text-align: center;
        line-height: 24px;
      }
      .subtitle {
        padding-top: 32px;
        font-size: 24px;
        font-weight: 400;
        color: rgba(18, 18, 35, 0.4);
        text-align: center;
        line-height: 32px;
      }
      .indented {
        padding-left: 80px;
        padding-right: 80px;
      }
      .forgotPasswordLink {
        color: $primary;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.2px;
        font-weight: 600;
        cursor: pointer;
        padding-top: 12px;
        padding-left: 12px;
        padding-right: 12px;
      }
      .labelLink {
        color: $primary;
        font-size: 16px;
        // line-height: 32px;
        letter-spacing: -0.2px;
        font-weight: 600;
        cursor: pointer;
      }
      .label {
        color: $secondary;
        font-size: 16px;
        letter-spacing: -0.2px;
        font-weight: normal;
      }
      .cancelLink {
        margin-top: 16px;
      }
      .buttonsWrapper {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .submitButton {
        padding-left: 32px;
        padding-right: 32px;
        height: 55px;
        border-radius: 12px;
        background-color: $primary !important;
        color: $white;
        font-weight: 700;
        font-size: 16px;
        cursor: pointer;
        display: flex;
        letter-spacing: -0.2px;
        align-items: center;
        justify-content: center;
      
        &:hover {
          box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
        }
      }
      .confirmIcon {
        text-align: center;
      }

      .disabled {
        cursor: not-allowed;
        opacity: 0.8;
        &:hover {
          box-shadow: none;
        }
      }
    }
  }
}

.formGroup {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.formLabel {
  font-weight: 400;
  font-size: 18px;
  letter-spacing: -0.2px;
  color: $secondary;
  margin-bottom: 11px;
  margin-left: 10px;
  display: flex;
  align-items: center;
}

.formInputCont {
  height: 50px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #EAEAF1;

  &.focused {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  }
}

.formInput {
  width: 100%;
  height: 50px;
  padding: 8px 16px;
  box-sizing: border-box;
  outline: none;
  border: none;
  background-color: transparent;
  font-size: 16px;
}

.termsOfServiceLink {
  text-decoration: none;
}

.twoFAInput {
  text-align: center;
  width: 40%;
  outline: none;
  height: 60px;
  border-radius: 12px;
  border: 2px solid rgb(118, 118, 118);
  padding: 8px 16px;
  box-sizing: border-box;
  font-size: 45px;
  background-color: #F4F5F8;
}