@import "../../pages/Auth/styles.module";

.Background {
  .bg1 {
    position: absolute;
    width: 85% !important;
    height: 123.5% !important;
    svg {
      width: 100%;
      height: 100%;
    }

    animation: rotateBackground $authAnimationLength forwards;
    -webkit-animation: rotateBackground $authAnimationLength forwards;

    @keyframes rotateBackground {
      from {
        transform: translate(-16%, -30%) rotate(90deg);
        opacity: 0;
      }

      to {
        transform: translate(-16%, -26.5%) rotate(40deg);
        opacity: 1;
      }
    }
  }
  .bg2 {
    position: absolute;
    width: 34% !important;
    height: 49.4% !important;
    svg {
      width: 100%;
      height: 100%;
    }

    animation: rotateBackground2 $authAnimationLength forwards;
    -webkit-animation: rotateBackground2 $authAnimationLength forwards;

    @keyframes rotateBackground2 {
      from {
        transform: translate(180%, 100%) rotate(36deg);
        opacity: 0;
      }

      to {
        transform: translate(190%, 60%) rotate(-28deg);
        opacity: 1;
      }
    }
  }
}