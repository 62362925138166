@import '../../style/colors';

.title {
  text-align: center;
  font-weight: 800;
  font-size: 32px;
  line-height: 110%;
  color: #101828;
  padding: 0 32px;
}

.subtitle {
  color: $secondary;
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 35px;
  width: 83%;
  margin-left: 8.5%;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 20px;
  margin-bottom: 30px;
}

.button {
  box-sizing: border-box;
  padding: 18px 32px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 16px;
  line-height: 119%;
  text-align: center;
  letter-spacing: -0.2px;
  user-select: none;
  font-weight: 700;
}

.confirmButton {
  width: 168px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $primary;
  font-size: 16px;
  font-weight: 700;
  color: $white;
  cursor: pointer;
  border: none;
  outline: none;
  transition: box-shadow 0.3s;

  &:hover {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.8);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
    box-shadow: none;
  }
}

.cancelButton {
  font-weight: 600;
  color: $primary;
  cursor: default;

  a {
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
}

.scrollableTextBox {
  width: 100%;
  height: 50px;
  padding: 8px 5px;
  font-size: 16px;
  box-sizing: border-box;
  outline: none;
  border: none;
  background-color: transparent;
  overflow-x: scroll;
  white-space: nowrap;
  resize: none;
}

.formGroup {
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 0;
  }
}

.formInputCont {
  height: 50px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border-radius: 12px;
  background: $grey-100;
  color: $secondary;

  padding-top: 10px;

  &.focused {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  }

  textarea {
    background: $grey-100;
    color: $secondary;
    font-weight: 700;
    font-size: 20px;
    line-height: 120%;
    padding: 10px 30px;
  }
}

.docsLink {
  color: dodgerblue;
}

.linkUnderline {
  &:hover {
    text-decoration: underline;
    opacity: 0.9;
  }
}