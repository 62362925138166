@import "../../style/colors";

.header {
  position: fixed;
  height: 80px;
  top: 0;
  left: 0;
  right: 0;
  padding: 12px 48px 12px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 100;
  box-sizing: border-box;
  background-color: $secondary;
}

.left {
  flex: 1;
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.logo {
  height: 50px;
  cursor: pointer;

  img {
    height: 100%;
  }
}

.logoSmall {
  display: none;
}

.searchcont {
  margin-left: 30px;
  flex: 1;
  max-width: 400px;
  height: 50px;
  box-sizing: border-box;
  border-radius: 10px;
  position: relative;
  background: rgba(255, 255, 255, 0.16);

  ::placeholder {
    color: $white;
    opacity: 0.8;
  }
}

.searchbar {
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 14px;
  box-sizing: border-box;
}

.searchicon {
  width: 22px;
  height: 22px;
  padding-right: 5.5px;
  color: $white;
}

.searchinput {
  width: 100%;
  flex: 1;
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  color: $white;
}

.searchinput::placeholder {
  color: $white;
  opacity: 1;
}

.resultcont {
  padding: 0 0;
  background-color: $secondary;
}

.resultsection {
  margin-bottom: 6px;
  color: $white;
  background-color: $secondary;

  &:first-child {
    margin-top: 6px;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

.resultsectiontitle {
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  padding: 5px 10px;
}

.separator {
  width: 90%;
  margin: 8px 0 8px 5%;
  border-bottom: 1px solid $white;
}

.result {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  cursor: pointer;
  text-decoration: none;
  color: $white;
  padding: 5px 10px;
}

.resultimg {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  margin-right: 14px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.resulttitle {
  flex-grow: 1;
  width: 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.noResults {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0 15px;
  font-style: italic;
  color: $white;
  background-color: $secondary;
}

.menu {
  display: flex;
  align-items: center;

  .searchcont {
    display: none;
  }
}

.secondmenu {
  display: none;
  align-items: center;
  margin-left: auto;
}

.menuLink {
  margin-right: 48px;
  text-decoration: none;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  user-select: none;
  cursor: pointer;
  position: relative;
  opacity: 0.5;
  color: $white;

  &.active {
    opacity: 1;
  }

  &:last-child {
    margin-right: 0;
  }
}

.account {
  display: flex;
  align-items: center;
}

.avatar {
  width: 32px !important;
  height: 32px !important;
  border-radius: 50% !important;
  margin-right: 12px;
  overflow: hidden;
}

.profile {
  margin-right: 4px;
}

.address {
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
}

.network {
  margin-top: 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  opacity: 0.6;
}

.account,
.connect {
  box-sizing: border-box;
  padding: 8px 12px 11px 12px;
  border-radius: 10px;
  background-color: $white;
  color: $secondary;
  opacity: 1;

  &:hover {
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  }
}

.connect {
  height: 55px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  background-color: $primary;
  color: $white;
}

.expand {
  transition: transform ease 200ms;

  &.expanded {
    transform: rotate(-180deg);
  }
}

.profilemenu {
  width: 250px;
  transform: translateY(50px) !important;
  padding: 0;
  border-radius: 8px !important;
}

.menuList {
  padding: 20px 0 !important;
  width: 100%;
}

.menuItem {
  width: 100%;
  height: 48px;
  padding: 0 23px !important;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: $secondary;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0 !important;
  }

  &:hover {
    font-weight: 700;
    background-color: #eaeaf1;
  }
}

.menuIcon {
  width: 16px;
  height: auto;
  margin-right: 14px;
}

.menuSeparator {
  width: calc(100% - 32px);
  height: 0.5px;
  background-color: #eaeaf1;
  margin: 0 16px;
}

.signOut {
  width: calc(100% - 40px);
  height: 48px;
  border-radius: 10px;
  margin: 20px auto 0;
  background-color: $primary;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.02em;
  color: $white;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.1);
  }
}

.hasBorder {
  border-bottom: 1px solid #d9e1ee;
}

@media only screen and (max-width: 900px) {
  .logoBig {
    display: none;
  }

  .logoSmall {
    display: block;
  }

  .profile {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .header {
    flex-direction: column;
  }

  .left {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;

    .searchcont {
      display: none;
    }
  }

  .menu {
    width: 100%;
    justify-content: flex-end;

    .link {
      display: none;
    }

    .searchcont {
      display: block;
      margin-left: 0;
      margin-right: 20px;
    }
  }

  .secondmenu {
    display: flex;
  }
}
