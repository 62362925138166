@import '../../style/colors';
@import 'react-alice-carousel/lib/alice-carousel.css';

.modalPopup {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
  }
  .modalSliderContainer {
    width: 100%;
    height: 650px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    outline: none;
    .subModalContainer {
      width: 100%;
      height: 635px;
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      .carouselContainer{
        width: 1000px;
      }
    }
    .modalSliderCounter {
      height: 15px;
      color: white;
    }
    .leftButton{
      justify-content: end;
      margin-right: 30px;
    }
    .rightButton{
      justify-content: start;
      margin-left: 30px;
    }
    .imgStyle {
      width:1000px;
      height: 620px;
    }
    .sliderButtonWrapper {
      width: 100%;
      display: flex;
      align-items: center;
      height: 100%;
      cursor: pointer;
      .arrow {
        border: solid white;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
      }
      .arrowLeft {
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
      }
      .arrowRight {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
      }
    }
    .closeLabel {
      position: absolute;
      color: white;
      font-weight: 800;
      font-size: 16px;
      line-height: 120%;
      top: 60px;
      right: 60px;
      cursor: pointer;
      @media screen and (max-width: 1700px) {
        top: 20px;
      }
    }
  }