@import "../../style/colors";

$bgDefaultWidth: 1920px;
$bgDefaultHeight: 410px;

.container {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  padding-top: 80px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
}

.profile {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  background: $grey-100;
}

.banner {
  width: 100%;
  height: 100%;
  position: absolute;
  margin-bottom: -86px;
}

.bannerImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.bannerPlaceholder {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;

  .colorBackground {
    position: absolute;
    width: 100%;
    height: 30%;
    top: 70%;
    background: linear-gradient(180deg, rgba(244, 245, 248, 0) 0%, #F4F5F8 100%);
    z-index: 3;
  }

  .bg1 {
    position: absolute;
    width: 1.8*$bgDefaultWidth !important;
    height: 1.8*$bgDefaultHeight !important;
    z-index: 2;
    svg {
      width: 100%;
      height: 100%;
      filter: drop-shadow(0 0 3rem #EFF1F4);
      transform: translate(-19%, 2%) rotate(120deg);
    }
  }

  .bg2 {
    position: absolute;
    width: 1.8*$bgDefaultWidth !important;
    height: 1.8*$bgDefaultHeight !important;
    z-index: 2;
    svg {
      width: 100%;
      height: 100%;
      filter: drop-shadow(0 0 3rem #EFF1F4);
      transform: translate(-44%, -46%) rotate(-180deg);
    }
  }

  .bg3 {
    position: absolute;
    width: 1.0*$bgDefaultWidth !important;
    height: 1.0*$bgDefaultHeight !important;
    z-index: 1;
    svg {
      width: 100%;
      height: 100%;
      filter: drop-shadow(0 0 3rem #EFF1F4);
      transform: translate(-20%, -40%) rotate(45deg);
    }
  }

  .bg4 {
    position: absolute;
    width: 1.0*$bgDefaultWidth !important;
    height: 1.0*$bgDefaultHeight !important;
    z-index: 1;
    svg {
      width: 100%;
      height: 100%;
      filter: drop-shadow(0 0 3rem #EFF1F4);
      transform: translate(26%, 10%) rotate(-20deg);
    }
  }
}

.editBanner {
  position: absolute;
  top: 32px;
  right: 32px;
  width: 48px;
  height: 48px;
  border-radius: 10px;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 5;
  border: 1px solid #E3E3E3;
  backdrop-filter: blur(8px);

  &:hover {
    box-shadow: 0 0 4px rgba(0, 0, 0, .1);
  }
}

.editIcon {
  width: 20px;
  height: 20px;
}

.buttonsWrapper {
  display: flex;
  align-items: center;
  z-index: 5;
}

.settings {
  width: 48px;
  height: 48px;
  border-radius: 10px;
  border: 1px solid #E3E3E3;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 17px;
  background: rgba(255, 255, 255, 0.84);

  &:last-child {
    margin-right: 0;
  }
}


.wrapper {
  min-height: 410px;
  z-index: 6;
}
.wrapperRow {
  display: flex;
  align-items: flex-end;
  height: 100%;
  margin-left: 80px;
}
.avatarWrapper {
  min-width: 160px;
  min-height: 160px;
  border-radius: 50%;
  z-index: 6;
  overflow: hidden;
  position: relative;
  border: 4px solid $white;
  margin-bottom: -30px;
}

.avatar {
  position: absolute;
  top: 0;
  left: 0;
  width: 160px;
  height: 160px;
}

.defaultAvatar {
  width: 160px;
  height: 160px;

  img {
    width: 160px;
    height: 160px;
  }
}

.usernameWrapper {
  display: flex;
  align-items: center;
}

.username {
  font-size: 32px;
  font-weight: 800;
  color: $secondary;
  line-height: 39px;
  white-space: nowrap;
}

.followBtn {
  margin-left: 16px;
  width: 80px;
  height: 26px;
  border-radius: 4px;
  background-color: $primary;
  color: $white;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 0 4px rgba(0, 0, 0, .4);
  user-select: none;

  &:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
  }
}

.bottomWrapper {
  padding: 20px 32px 20px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  z-index: 7;
}

.bottomWrapperInner {
  display: flex;
  align-items: center;
  border-radius: 32px;
  background: rgba(255, 255, 255, 0.84);
  backdrop-filter: blur(8px);
  padding: 12.5px 28px;
  margin-right: 10px;
}


.addressWrapper {
  width: fit-content;
  height: 40px;
  margin-left: 36px;
  padding-left: 16px;
  padding-right: 6px;
  display: flex;
  align-items: center;
  border-radius: 70px;
  background: rgba(18, 18, 35, 0.04);
}

.address {
  font-size: 14px;
  font-weight: 700;
  color: $primary;
}

.copyIcon {
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background-color: $primary;
  margin-left: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

}

.tooltip {
  font-size: 16px;
}

.followers {
  margin-left: 36px;
  font-size: 16px;
  font-weight: 400;
  color: $secondary;
  cursor: pointer;
}

.followersNumber {
  font-weight: 800;
}

.bio {
  width: 90%;
  max-width: 760px;
  margin-top: 6px;
  font-size: 20px;
  line-height: 32px;
  font-weight: 400;
  color: rgba(0, 0, 0, .6);
}

.content {
  width: 100%;
  max-height: calc(100vh - 80px);
  box-sizing: border-box;
  display: flex;
  z-index: 5;
}

.contentSidebar {
  width: 288px;
  flex: 0 0 288px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $secondary;
  background: $grey-100;
  padding-top: 65px;
}

.tabsGroup {
  width: 100%;
  padding: 35px 35px 16px 32px;
  box-sizing: border-box;

  &:last-child {
    border-bottom: 0;
  }
}

.groupTitle {
  font-size: 24px;
  line-height: 133%;
  font-weight: 800;
  margin-bottom: 16px;
  opacity: 1;
}

.contentBody {
  flex-grow: 1;
  height: fit-content;
  box-sizing: border-box;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: auto;
  display: flex;
  flex-flow: wrap;
  padding-top: 65px;
}

.filterHeader {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 24px 0 12px 0;
  width: 100%;
}

.tab {
  margin-left: -32px;
  margin-right: -35px;
  padding-left: 32px;
  padding-right: 35px;
  height: 48px;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  opacity: 0.6;

  &.selected {
    background-color: rgba(18, 18, 35, 0.06);
    color: $secondary;
    opacity: 1;
  }
}

.tabIcon {
  margin-right: 16px;
}

.tabIconSelected {
  color: $primary;
}

.tabLabel {
  font-weight: 700;
  margin-right: auto;
}

.tabCountLoading {
  transform: translateY(-4px);
}

.table {
  width: 100%;
  height: fit-content;
  text-align: left;
  border-collapse: collapse;
  vertical-align: middle;
  position: relative;
}

.table tr {
  padding-left: 15px;
}

.table thead tr {
  height: 50px;
}

.table tbody tr {
  height: 70px;
}

.table td {
  min-width: 90px;
}

.table th:first-child{
  padding-left: 15px;
}

.table td:first-child {
  padding-left: 15px;
}

.activityHeader,
.activity {
  min-width: 900px;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: $secondary;
}

.activityHeader {
  width: 100%;
  padding: 9px 14px;
}

.walletVerificationContainer,.kycAuthentificationContainer,.securityContainer {
  text-align: center;
  width: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
  .submainSubtitle {
    font-weight: 800;
    font-size: 20px;
    line-height: 120%;
    text-align: center;
    color: #121223;
    margin-top: 40px;
    margin-bottom: 26px;
  }
  .walletVerificationHeader,.kycAuthentificationTitle,.securityHeader {
    padding-top: 60px;
    font-weight: 800;
    font-style: normal;
    font-size: 40px;
    line-height: 110%;
    color: $secondary;
    margin-bottom: 2px;
  }
  .walletVerificationSubtitle,.kycAuthentificationSubtitle,.securitySubtitle {
    padding-top: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
    color: $secondary;
    opacity: 0.4;
    text-align: center;
  }
  .walletVerificationInfo, .kycAuthentificationInfo {
    text-align: justify;
    padding: 10px 10px 0;
    font-weight: normal;
    font-style: normal;
    font-size: 18px;
    line-height: 110%;
    color: $secondary;
    max-width: 600px;
    margin: 0 auto 2px;
  }
  .learnMoreLink {
    &:hover {
      text-decoration: underline;
      opacity: 0.9;
    }
  }
  .label2FAContainer{
    display: flex;
    justify-content: center;
    .label2FA {
      max-width: 820px;
      margin: 0;
    }
  }
  .walletVerificationLabel {
    font-size: 16px;
    font-weight: 400;
    line-height: 120%;
    color: $secondary;
    opacity: 0.4;
    padding-top: 5px;
  }
  .walletAddress {
    padding-top: 4px;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
    color: $secondary;
    text-align: center;
  }
  .tabButtonsWrapper {
    padding-top: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .veifyButton {
    padding-top: 30px;
  }
  .submitButton {
    padding: 18px 32px;
    border-radius: 12px;
    background-color: $primary !important;
    color: $white;
    font-weight: 700;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    letter-spacing: -0.2px;
    align-items: center;
    justify-content: center;
  
    &:hover {
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
    }
  }
  .cancelButton {
    padding: 18px 32px;
    border-radius: 12px;
    background-color: #F4F5F8;
    color: $primary;
    font-weight: 700;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    letter-spacing: -0.2px;
    align-items: center;
    justify-content: center;

    &:hover {
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
    }
  }
  .walletVerificationOkIcon,.kycAuthentificationOkIcon,.securityOkIcon {
    width: 72px;
    height: 72px;
  }
}

.instructionSteps {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  
  .instructionContainer {
    width: 347px;
    height: 302px;
    background: #F4F5F8;
    border-radius: 8px;
    padding: 30px;
    margin: 11px;
    box-sizing: border-box;

    .instructionHeader {
      font-style: normal;
      font-weight: 800;
      font-size: 28px;
      line-height: 110%;
      margin: 4px;
    }

    .instructionDescription {
      font-weight: 700;
      font-size: 16px;
      line-height: 120%;
      text-align: center;
      margin: 4px;
    }
  }
}

.activity {
  padding: 14px;
  border-bottom: 1px solid rgba(0, 0, 0, .1);

  .name,
  .owner {
    color: $primary;
    text-decoration: none;
  }
}

.activity + *:not(.ownerAvatarWrapper) {
  white-space: nowrap;
  padding-right: 16px;
}

.activityList {
  width: fit-content;
  min-width: 100%;
}


.name {
  display: flex;
  align-items: center;

  .media {
    min-width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;

    .mediaInner {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 4px;
    }
  }
}

.price {
  display: flex;
  align-items: center;
  vertical-align: middle;
  height: 100%;
}

.headerName {
  width: 35%;
}
.headerTo {
  width: 15%;
}
.headerPrice {
  width: 15%;
}
.headerDate {
  width: 10%;
}
.headerSwitch {
  width: 15%;
}
.headerButton {
  width: 15%;
}


.owner {
  display: flex;
  align-items: center;
}


.tokenLogo {
  width: 24px;
  height: 24px;
  margin-right: 8px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}

.ownerAvatarWrapper {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  margin-right: 6px;
}

.ownerAvatar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.disabled {
  cursor: not-allowed;
  box-shadow: none !important;
  opacity: 0.7;
}

.shareMenu {
  transform: translateY(55px) !important;
}

.shareMenuList {
  padding: 0 !important;
}

.menuItem {
  height: 48px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: $secondary;

  img {
    width: 20px;
    margin-right: 16px;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    padding-top: 133px;
  }

  .content {
    max-height: calc(100vh - 133px);
  }

  .contentSidebar {
    width: 240px;
    flex: 0 0 240px;
  }
}

@media only screen and (max-width: 600px) {
  .buttonsWrapper {
    flex-direction: column;
  }

  .settings {
    margin-right: 0;
    margin-bottom: 16px;
  }

  .bottomWrapper {
    flex-direction: column;
  }

  .wrapper {
    margin-left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .bio {
    text-align: center;
  }

  .followers {
    margin-left: 0;
    margin-top: 16px;
  }

  .contentSidebar {
    width: 60px;
    flex: 0 0 60px;
  }

  .contentBody {
    padding: 12px 18px 18px;
    min-height: 100%;
  }

  .tabsGroup {
    padding: 32px 0;
  }

  .tab {
    padding: 0 12px;
    margin: 0;
    justify-content: center;
  }

  .tabIcon {
    margin-right: 0;
  }

  .tabLabel,
  .tabCount {
    display: none;
  }

  .groupTitle {
    display: none;
  }
}

.formInputCont {
  width: 267px;
  outline: none;
  height: 60px;
  border-radius: 10px;
  border: 1px solid #EAEAF1;
  padding: 8px 16px;
  box-sizing: border-box;
  font-size: 16px;
  margin-top: 26px;
}

.googleIcon {
  width: 73px;
  height: 73px;
  margin: 16px;
}

.qrcodeImg {
  width: 118px;
  height: 118px;
  margin: 16px;
}
.buttonContainer {
  display: flex;
  justify-content: space-between;
  
  .buttonImag {
    width: 132px;
    height: 44px;
    cursor: pointer;

    &:hover {
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
    }
  }
}

.errorValidate {
  box-shadow: 0 0 10px 1px rgba(236, 28, 28, 0.76);
}

.makeOffer {
  width: 120px;
  height: 35px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $primary;
  font-size: 14px;
  font-weight: 500;
  color: $white;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.8);
  }
}

.optionPanel {
  position: absolute;
  top: -50px;
  right: 20px;
  margin-left: 0 !important;

  &:last-child {
    border-bottom: 0;
  }
}

.option {
  margin-left: 5px;
}

.optionTitle {
  font-size: 14px;
  line-height: 17px;
  font-weight: 700;
}

.optionDesc {
  font-size: 12px;
  line-height: 140%;
  font-weight: 400;
  color: #A2A2AD;
  margin-top: 7px;
}