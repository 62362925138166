@import "../../style/colors";

.option {
  width: 100%;
  height: 48px;
  padding: 0 16px;
  border: 1px solid #EAEAF1;
  margin-bottom: 16px;
  border-radius: 6px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, .2);
  }

  &.active {
    border-color: $primary;
  }
}

.header {
  font-weight: 500;
  font-size: 16px;
  color: $secondary;
}

.icon {
  height: 24px;
}

.text {
  font-size: 16px;
  color: $secondary;
}

.switchBtn {
  margin-top: 16px;
  width: fit-content;
  height: 48px;
  padding: 0 16px;
  background: $primary;
  color: $white;
  font-weight: 700;
  border-radius: 12px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
  }
}

.title {
  text-align: center;
  font-weight: 800;
  font-size: 32px;
  line-height: 110%;
  color: #101828;
}

.subtitleContainer {
  text-align: center;
}

.subtitle {
  color: $secondary;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  text-align: left;
  opacity: 0.5;
  margin-top: 15px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  //margin-left: 5%;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 15px;
  margin-bottom: 40px;
}