.formGroup {
    position: relative;
  }
  
  .formNotation {
    margin: 0 0 8px;
    text-align: center;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    max-width: 120px;
  }
  
  .formInput {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 202px;
    min-height: 202px;
    outline: none;
    padding: 8px 16px;
    box-sizing: border-box;
    font-size: 16px;
    background: #F4F5F8;
    border-radius: 12px;
    color: #121223;

    &:hover {
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
    }
  }
  
  .fileInput {
    height: 1px;
    width: 1px;
    position: absolute;
    z-index: -1;
    opacity: 0;
    overflow: hidden;
  }

  .labelButton {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    width: 240px;
    height: 48px;
    border-radius: 8px;
    font-weight: 700;
    font-size: 18px;
    background-color: #1969FF;
    color: #FFF;
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .uploadFile{
    height: 100%;
    border-radius: 50%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 50;
    font-size: 25px;
    line-height: 24px;
    color: white;
    background: #7F56D9;
    position: relative;
    width: 56px;
    height: 56px;
    margin-bottom: 15px;
  }

  .uploadtitle{
    text-align: center;
    margin: 3px;
  }

  .isFocused {
    color:#1969FF;
    border-style: solid;
  }
  .isDragAccept {
    color:green;
    border-style: solid;
  }
  .board {
    width: 100%;
    min-height: 100px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
  }

  .waitingUpload {
    pointer-events:none;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
