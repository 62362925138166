@import "../../style/colors";

.AdminDashboardItem {
  display: flex;
  justify-content: space-between;

  box-shadow: 0px 20px 80px rgba(18, 18, 35, 0.08);
  border-radius: 8px;
  background-color: $white;

  padding: 20px 32px 20px 20px;

  margin-bottom: 20px;

  .head {
    display: flex;
    width: 100%;

    .img_container{
      width: 80.81px;
      text-align: center;
      align-self: center;
    }

    img {
      max-width: 80.81px;
      max-height: 80px;

      border-radius: 8px;
    }

    .text_container {
      display: flex;
      flex-direction: column;
      justify-content: center;

      margin-left: 32.19px;
      
      .title {
        font-size: 20px;
        line-height: 24px;
        font-weight: 800;
      }

      .description {
        max-height: 5em;
        font-size: 14px;
        line-height: 24px;
        word-break: break-word;
        overflow: hidden;
      }
    }
  }

  .body {
    display: flex;
    max-width: 592px;
    width: 100%;
    margin-right: 13%;
    margin-left: 5%;

    .body_item {
      display: flex;
      flex-direction: column;
      justify-content: center;

      max-width: 146px;
      width: 100%;

      font-size: 14px;
      line-height: 24px;
      color: rgba(18, 18, 35, 1);

      .label {
        font-weight: 400;
        color: #A2A2AD;
      }

      .date {
        font-weight: 800;
        white-space: nowrap;
      }

      .status {
        font-weight: 800;
      }
    }
    .body_item:not(:last-child) {
      margin-right: 13%;
    }
    .body_item:last-child {     
      .price2 {
        color: $secondary;
        white-space: nowrap;
        display: flex;
        align-items: center;
        margin: 0;
      
        img {
          height: 12px;
          margin-right: 4px;
        }
      }
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    justify-content: center;

    font-size: 24px;
    line-height: 32px;
    font-weight: 800;
    width: 200px;

    align-self: center;
    
    .price {
      color: $secondary;
      white-space: nowrap;
      display: flex;
      align-items: center;
      margin: 0;
      justify-content: flex-end;
      width: 120px;
    
      img {
        height: 18px;
        margin-right: 4px;
      }
    }
  }
}

.AdminDashboardItem:not(:last-child) {
  margin-bottom: 20px;
}

.nftLink {
  text-decoration: none;
  color: inherit;
}