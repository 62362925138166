@import "../../style/colors";

.container {
  text-align: center;
  margin: 0 auto 100px;
  height: 100%;
  max-width: 950px;
  padding: 150px 20px 20px;
  font-size: 15px;
  line-height: 150%;
  color: $secondary;

  counter-reset:section;

  h2:before {
    counter-increment:section;
    content: counter(section) ". ";
    font-weight:bold;
  }

  .header {
    margin-top: 50px;
    margin-bottom: 20px;
    text-align: left;
    line-height: 120%;
  }

  .firstHeader {
    margin-top: 0;
    font-size: 60px;
    font-weight: 700;
  }

  .lastUpdated {
    font-weight: 500;
    opacity: 0.7;
    margin-bottom: 50px;
  }

  .address {
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  p {
    text-align: left;
    margin: 0 0 15px 0;
    line-height: 1.5;
  }

  ul {
    padding-left: 24px;
    list-style-type: disc;

    li {
      list-style-type: disc;
      text-align: left;
    }
  }

  ol {
    text-align: left;
  }

  .sublist li {
    list-style-type: circle;
  }
}

a {
  text-decoration: none;
  color: $primary;
}