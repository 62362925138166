// Colors
$violet: #7F56D9;
$dark: #121223;
$white: #FFFFFF;
$red: #FF3434;

$grey-100: #F4F5F8;
$grey-200: #E3E3E3;
$grey-700: #929292;

// Theme colors
$primary: $violet;
$secondary: $dark;
$danger: $red;
