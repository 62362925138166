.board {
    width: 100%;
    margin-bottom: 5px;
  }

  .image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .uploadCont {
    height: 100%;
    border-radius: 8px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    position: relative;
    padding: 10px;
  }
  .labelEmptyCont{
    align-items: flex-start;
  }
  .emtyCont {
    background-color: #F4F5F8;
    color: rgba(0, 0, 0, .6);
    cursor: pointer;
    &:hover {
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
    }
  }

  .browse {
    color: #1969FF;
    cursor: pointer;
  }

  .waitingUpload {
    pointer-events:none;
  }

  .uploadsubtitle {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    color: #121223;
    margin: 8px;
    opacity: 0.5;
  }
  .icon {
    margin-top: 32px;
    height: 20px;
  }

  .formatList {
    margin-bottom: 38px;
  }

  .additionalImages {
    display: flex;
    flex-wrap: wrap;
    justify-self: start;
    gap: 10px;
  }

  .deleteImage {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 240px;
    height: 150px;
    border-radius: 8px;
    img {
      width: 240px;
      height: 150px;
      object-fit: cover;
      border-radius: inherit;
      cursor: unset;
    }
    .deleteIcon {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;  
      width: 240px;
      height: 150px;
      background-color: red;
      color: #FFF;
      z-index: 5;
      opacity: 0;
      border-radius: inherit;
      cursor: pointer;
    }
    .deleteIcon:hover {
      opacity: 0.5;
      cursor: pointer;
    }
    .smallIcon {
      width: 202px;
      height: 126px;
    }
  }
  .smallImage {
    width: 202px;
    height: 126px;
  }
  .addPhotoButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #F4F5F8;
    border-radius: 8px;
    cursor: pointer;
    &:hover {
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
    }
      
    .iconSubtitle {
      margin: 5px;
      opacity: 0.5;
      font-size: 60px;
      font-weight: 100;
    }
  }

  .button {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    width: 112px;
    height: 55px;
    color: #FFF;
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: #7F56D9;
    border-radius: 12px;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    &:hover {
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
    }
  }
  .cancelButton {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    width: 112px;
    height: 55px;
    color: #7F56D9;
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: #FFF;
    border: 1px solid #7F56D9;
    border-radius: 12px;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
  
    &:hover {
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
    }
  }
  .popoverLabel {
    height: 100%;
    border-radius: 8px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    position: relative;
    padding: 10px;
    margin: 20px;
  }