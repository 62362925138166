@import "../../style/colors";

.container {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 999;
  align-items: center;
  justify-content: center;

  &.visible {
    display: flex;
  }
}

.modal {
  max-width: 90%;
  width: 600px;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: $white;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.4);
  position: relative;
}

.small {
  width: 400px;
}

.header {
  height: 70px;
  border-bottom: 1px solid #EAEAF1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
}

.headerWithoutTitle {
  flex-direction: row-reverse;
  border: none;
}

.body {
  padding: 40px 24px;
}

.bodyWithoutTitle {
  padding: 0 24px 10px 24px;
}

.title {
  font-weight: 700;
  font-size: 18px;
  color: $secondary;
}

.closeButton {
  width: 16px;
  height: 16px;
  cursor: pointer;

  img {
    width: 16px;
    height: 16px;
  }
}

.footer {
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.submitButton {
  min-width: 168px;
  height: 48px;
  box-sizing: border-box;
  padding: 0 12px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.5px;
  user-select: none;

  &:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
  }
}

.submitButton {
  background-color: $primary;
  color: $white;
}

.submitDenyButton {
  background-color: $danger;
}

.disabled {
  cursor: not-allowed;
  box-shadow: none !important;
  opacity: 0.7;
}

@media only screen and (max-width: 600px) {
  .modal {
    padding: 40px 20px;
  }

  .title {
    font-size: 20px;
  }

  .footer {
    flex-direction: column;
  }

  .listButton {
    width: calc(100% - 48px);
  }

  .listButton {
    margin-right: 0;
    margin-bottom: 20px;
  }
}
