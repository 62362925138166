@import "../../style/colors";

.root {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1000;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paper {
  width: 640px;
  border-radius: 10px;
  box-sizing: border-box;
  background-color: $white;
  outline: none;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
  color: #3D3D3D;
}

.header {
  height: 70px;
  border-bottom: 1px solid #EAEAF1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
}

.title {
  font-weight: 700;
  font-size: 18px;
  color: $secondary;
}

.closeButton {
  cursor: pointer;

  img {
    width: 16px;
  }
}

.body {
  padding: 24px;
}

.formGroup {
  margin-bottom: 25px;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }
}

.formLabel {
  margin: 0 0 11px 12px;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: $secondary;
}

.formInputCont {
  height: 50px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #EAEAF1;
}

.formInput {
  width: 100%;
  height: 50px;
  padding: 8px 16px;
  box-sizing: border-box;
  outline: none;
  border: none;
  background-color: transparent;
  font-size: 16px;
}

.usdPrice {
  min-width: 130px;
  height: 50px;
  border-left: 1px solid #EAEAF1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 16px;
  color: rgba(0, 0, 0, .4);
}

.longInput {
  resize: none;
  height: 100px;
  margin: 0;
}

.lengthIndicator {
  margin-top: 6px;
  margin-right: 6px;
  text-align: right;
  color: rgba(0, 0, 0, .6);
}

.footer {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}

.button {
  width: 169px;
  height: 48px;
  border-radius: 8px;
  font-weight: 700;
  font-size: 16px;
  background-color: $primary;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.save {
  background-color: $primary;
  color: $white;
};

.avatarBox {
  position: relative;
  width: 100px;
  height: 100px;
  background-color: #FAFAFA;
  border-radius: 100px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.avatar {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
}

.disabled {
  cursor: not-allowed;
  box-shadow: none;
  opacity: 0.7;
}

.itemList {
  height: 300px;
  border: 1px solid #EAEAF1;
  border-radius: 12px;
  overflow-y: auto;
  overflow-x: hidden;
}

.item {
  display: flex;
  align-items: center;
  padding: 10px 16px;
  user-select: none;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, .05);
  }

  &.selected {
    background-color: rgba(25, 105, 255, 0.2);

    .itemName {
      font-weight: 700;
    }
  }
}

.imageBox {
  width: 28px;
  height: 28px;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
}

.media {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.mediaLoading {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  left: 0;
}

.itemName {
  font-weight: 400;
  font-size: 16px;
  color: $secondary;
  margin-left: 12px;
}

@media only screen and (max-width: 600px) {
  .title {
    font-size: 20px;
  }

  .footer {
    flex-direction: column;
  }

  .button {
    width: calc(100% - 48px);
    margin-left: auto;
    margin-right: auto;
  }

  .save {
    margin-bottom: 20px;
  }
}
