@import "../../style/colors";

.root {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

.card {
  min-height: 300px;
  flex-grow: 1;
  cursor: pointer;
  transition: transform ease 0.1s;
  box-shadow: 0 0 15px rgba(18, 18, 35, 0.16);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  background-color: $white;

  &:nth-child(n+2) {
    position: absolute;
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
  }

  &:nth-child(2) {
    width: 93%;
    top: 4px;
  }

  &:nth-child(3) {
    width: 96%;
    top: 1px;
  }

  &:hover {
    box-shadow: 0 0 6px rgba(0, 0, 0, .4);
    border: none;
  }
}

.cardHeader {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 12px;
  color: rgba(0, 0, 0, .6);
  z-index: 5;
  &.liking {
    color: $primary;
  }
}

.favIcon {
  height: 20px;
  padding-top: 5px;
  color: white;
  &:hover {
    color: $primary;
  }
}

.buyIcon {
  height: 20px;
  padding-top: 5px;
  padding-left: 2px;
}

.favLabel {
  font-size: 16px;
  line-height: 20px;
}

.createBtn {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.createIcon {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: $primary;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 12px;
  }
}

.createLabel {
  font-size: 16px;
  font-weight: 700;
  color: $primary;
  margin-top: 21px;
}

.link {
  height: 100%;
  flex-direction: column;
  text-decoration: inherit;
}

.label {
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  margin-bottom: 4px;
  color: #A2A2AD;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.checkIcon {
  font-size: 18px !important;
  color: $primary;
  margin-left: 4px;
}

.price {
  font-weight: 700;
  font-size: 20px;
  color: $secondary;
  line-height: 120%;
  white-space: nowrap;
  display: flex;
  align-items: center;
  margin: 0;

  img {
    height: 16px;
    margin-right: 6px;
  }
}

.label2 {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 4px;
  color: #A2A2AD;
}

.price2 {
  font-weight: 700;
  font-size: 13px;
  color: $secondary;
  white-space: nowrap;
  display: flex;
  align-items: center;
  margin: 0;

  img {
    height: 12px;
    margin-right: 4px;
  }
}

.name {
  flex: 1;
  font-weight: 700;
  font-size: 15px;
  margin: 0;
  color: $secondary;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.name2 {
  flex: 1;
  font-weight: 700;
  font-size: 12px;
  margin: 0;
  color: $secondary;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.statusLabel {
  color: $primary;
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
}

.mediaPanel {
  width: 100%;
  height: 100%;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mediaLoading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.carousel {
  width: 100%;
  height: 100%;
}
.mediaBox {
  width: 100%;
  height: 75%;
  position: relative;
}
.imageBox {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.media {
  width: 100%;
  border-radius: 8px 8px 0 0;
  object-fit: cover;
  height: 100%;
  aspect-ratio: 2 / 1;
}

.dots {
  position: absolute;
  bottom: 10px;
  left: 12px;
}

.dot {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid #B0B0B0;
  background-color: #B0B0B0;
  box-sizing: border-box;
}

:global {
  .BrainhubCarousel__thumbnail {
    padding: 0 !important;
    opacity: 1 !important;
    margin-right: 5px;
  }

  .BrainhubCarousel__thumbnail--selected {
    :local {
      .dot {
        background-color: $primary;
      }
    }
  }
}

.content {
  padding: 18px 18px 15px 18px !important;
  height: 25%;
  display: flex;
  flex-direction: column;
}

.topLine {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.itemName {
  margin-right: 16px;
  overflow: hidden;
}

.alignBottom {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 6px;
  padding-bottom: 10px;
  min-height: 24px;
}

.alignRight {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.viewDetails {
  font-size: 14px;
  font-weight: 700;
  line-height: 120%;
  color: $primary;

  &:hover {
    opacity: 0.8;
  }
}

.status {
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 0;
  position: absolute;
  width: 100%;
  left: 0px;
  bottom: 0%;

  font-size: 14px;
  font-weight: 700;
  line-height: 120%;
  z-index: 5;
}
.status3, .status6, .status12 {
  background-color: $danger;
}
.status1, .status2, .status4, .status5, .status7,
.status8, .status9, .status10, .status11 {
  background-color: $primary;
}

.categoryTag {
  color: $white;
  text-align: center;
  position: absolute;
  width: 100%;
  height: fit-content;
  bottom: 0%;
  background-color: $primary;
  padding: 2.5px 0 1.5px 0;

  font-size: 14px;
  font-weight: 700;
}
.blurIcon {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.08);
  backdrop-filter: blur(2px);
  border-radius: 50%;
}

.statusIcon {
  stroke: $white;
  &:hover {
    stroke: $primary;
  }
}
