@import "../../style/colors";

.toast {
  cursor: pointer;
  padding: 0;
  border-radius: 10px;
  background-color: $white;
  box-shadow: 0 4px 40px rgba(0, 0, 0, .1);
}

.toastWithCloseIcon {
  cursor: default;
}

.toastInner {
  margin: -4px -10px;
  padding: 24px 34px 24px 24px;
}

.header {
  display: flex;
  align-items: center;

  .icon {
    width: 24px;
    height: 24px;
    object-fit: contain;
    margin-right: 14px;
  }

  span {
    font-weight: 700;
    font-size: 20px;
    line-height: 24.36px;
    color: #3D3D3D;
    white-space: pre-line;
  }
}

.body {
  margin-top: 12px;
  font-weight: 400;
  font-size: 16px;
  line-height: 25.6px;
  color: #3D3D3D;
  white-space: pre-line;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
}

.closeButton {
  position: absolute;
  right: 15px;
  top: 10px;
  width: 12px;
  height: 12px;
  opacity: 1;
  cursor: pointer;
}