@import '../../style/colors';

.Dashboard {
  .content {
    width: 100%;
    height: 100%;
    display: flex;
    padding-top: 80px;
    min-height: 100vh;
    box-sizing: border-box;
    overflow-y: auto;

    .sidebar {
      flex: 0 0 300px;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      overflow-y: auto;
      overflow-x: hidden;
      transition: flex-basis ease 300ms;
      background: $grey-100;
    
      .sidebarTitle {
        font-size: 28px;
        font-weight: 800;
        color: $secondary;
        line-height: 110%;
    
        padding: 29px 0 28px 24px;
      }
    }

    .list {
      width: 100%;
      padding: 32px 32px;

      .title {
        font-size: 40px;
        font-weight: 800;
        margin-bottom: 32px;
      }

      .paginationContainer {
        max-width: 350px;
        width: 100%;

        margin: 0 auto;
        margin-top: 32px
      }

      .csvReport {
        width: 175px;
        height: 33px;
        background: #7F56D9;
        border-radius: 100px;
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 120%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFFFFF;
        cursor: pointer;
      }
    }
  }
}

.filterHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 6px 0 16px 12px;
}
