@import "../../style/colors";

.footer {
  width: 100%;
  padding: 0 80px 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: $grey-100;
  color: $secondary;
  border-top: 1px solid rgba(18, 18, 35, 0.1);

  .footerContainer {
    padding: 0 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    line-height: 120%;
    font-size: 16px;

    .footerItem {
      display: flex;
      flex-direction: row;
      margin: 40px 5px;
    }

    .rightCornerWrapper {
      font-size: 20px;
      font-weight: 700;
      text-align: center;
      display: flex;
      gap: 40px;
      justify-content: right;
      padding-top: 40px;
    }
  }

  .logoLink {
    &:hover {
      opacity: 0.8;
    }
  }

  .textLink {
    color: $secondary;
    transition: color 300ms;
    &:hover {
      color: $primary;
    }
  }
  
  .logo {
    height: 35px;
  }

  .footerLine {
    display: block;
    margin-bottom: 30px;
    border: inset 1px $secondary;
    opacity: 0.1;
  }

  .socialIcon {
    color: #121223;
    transition: color 0.2s;

    &:hover {
      color: $primary;
    }
  }

  .socialIcon:not(:last-child) { margin-right: 25px }

  .socialIconTwitter {
    padding-bottom: 2px;
  }
}

.buttonsWrapper {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.subscribeButton {
  padding-left: 32px;
  padding-right: 32px;
  height: 55px;
  border-radius: 12px;
  background-color: $primary !important;
  color: $white;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  letter-spacing: -0.2px;
  align-items: center;
  justify-content: center;
  min-width: 135px;
  box-sizing: border-box;
  user-select: none;

  &:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
  }
}

.subscribeButtonDisabled {
  cursor: not-allowed;
  opacity: 0.8;

  &:hover {
    box-shadow: none;
  }
}

.formGroup {
  display: flex;
  flex-direction: column;
  margin: 50px 5px 40px;
  position: relative;
}

.error {
  position: absolute;
  left: 5px;
  bottom: -30px;
  color: rgba(235, 87, 87);
  font-size: 13px;
}

.formLabel {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: $secondary;
  margin-bottom: 15px;
  margin-left: 15px;
  display: flex;
  align-items: center;
}

.formInputCont {
  height: 50px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;

  :not(:last-child) {
    margin-right: 15px;
  }
}

.formInput {
  width: 100%;
  height: 60px;
  padding: 14px 0 14px 24px;
  box-sizing: border-box;
  outline: none;
  border: none;
  background-color: $white;
  border-radius: 12px;
  font-size: 20px;
  line-height: 32px;

  &.focused {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  }

  &::placeholder {
    color: $secondary;
    opacity: 0.2;
  }
}

.formInputError {
  background-color: #FFcdd2;
}