.filterHeaderRoot {
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-right: 20px;
}

.filterHeaderLeft {
    display: flex;
    align-items: center;
    flex-flow: wrap;
}

.filterCollectionsList {
    margin-left: 20px;
    display: flex;
    align-items: center;
    flex-flow: wrap;
}

.filterCollectionItem {
    display: flex;
    align-items: center;
    border-radius: 100px;
    background-color: #F4F5F8;
    padding: 8px 8px 8px 14px;
    margin-right: 12px;

    font-size: 14px;
    font-weight: 700;
    line-height: 120%;
    color: #121223;
}

.filterCollectionItem:hover {
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.1);
}

.filterCollectionItemLogo {
    width: 24px;
    height: 24px;
    border-radius: 50%;
}

.filterCollectionItemName {
    white-space: nowrap;
    margin: 0 5px 0 0;
}

.filterCollectionRemoveItem {
    cursor: pointer;
}

.filterClearAllFiltersLabel {
    color: #7F56D9;
    cursor: pointer;
    margin-left: 4px;

    font-size: 14px;
    font-weight: 700;
    line-height: 120%;
}

.filterClearAllFiltersLabel:hover {
    opacity: 0.8;
}

.filterResultLabel {
    font-weight: 500;
    font-size: 18px;
    color: rgba(61, 61, 61, .6);
    white-space: nowrap;
    margin-right: 20px;
    margin-top: 10px;
}

.MuiSelect-outlined.MuiSelect-outlined {
    padding-right: 32px;
    height: 10px !important;
}

.filterSelectGroup {
    color: #121223 !important;;
    font-size: 14px !important;
    font-weight: 700 !important;;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    line-height: 120% !important;
}

.filterSelectGroupLabel {
    opacity: 0.6;
}

.filterHeaderFormControl {
    margin-right: 18px !important;
    top : unset !important;
}

.filterHeaderFormControl:last-child {
    margin-right: 0 !important;
}

.selectBox {
    padding: 0 16px 0 8px;
    box-sizing: border-box;
    color: #121223 !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 120% !important;
    opacity: 1;
}

.selectBox::before,
.selectBox::after {
    display: none;
}

.selectInner {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.selectMenu {
    background-color: transparent !important;
}

.selectIcon {
    right: 10px !important;
    padding-top: 1px !important;
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
}

.selectIconOpen {
    transform: rotate(180deg) !important;
    top: calc(50% - 15px) !important;
}

.menuPropsPaper {
    box-sizing: border-box;
    background-color: #FFF;
    border-radius: 8px !important;
    box-shadow: 0 0 8px 2px rgba(0,0, 0, 0.15);
    transform: translateY(35px) !important;
}

.menuItemList {
    padding: 0 !important;
}

.menuItem {
    width: 100% !important;
    font-weight: 400 !important;
    font-size: 14px;
    line-height: 120%;
    padding: 12px !important;
    color: #121223 !important;
    background-color: transparent !important;
}

.menuItem:hover {
    background: rgba(0, 0, 0, 0.04) !important;
}

.menuItem:last-child {
    margin-bottom: 0 !important;
}

.menuItemSelected {
    background: rgba(0, 0, 0, 0.08) !important;
    font-weight: 700 !important;
}

@media only screen and (max-width: 600px) {
    .filterHeaderRoot {
        padding-right: 0;
        flex-direction: column-reverse;
        align-items: center;
    }

    .filterHeaderLeft {
        margin-top: 6px;
    }

    .filterSelectGroup {
        display: flex;
        flex-direction: column;
    }

    .filterHeaderFormControl {
        margin-right: 0 !important;
        margin-bottom: 12px !important;
    }

    .filterHeaderFormControl:last-child {
        margin-bottom: 0 !important;
    }
}
