.mapContainer {
  width: 100%;
  height: 318px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.errorValidate {
  box-shadow: 0 0 10px 1px rgba(236, 28, 28, 0.76);
}

.formInput {
  width: 100%;
  outline: none;
  height: 50px;
  border-radius: 10px;
  border: 0;
  padding: 8px 16px;
  box-sizing: border-box;
  font-size: 16px;
  background-color: #F4F5F8;
}

.geoPopper {
  width: 614px;
  padding-left: 16px;
  padding-right: 16px;
  background-color: #F4F5F8;
  z-index: 999;
}