@import '../../style/colors';

.modalContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 762px;
  height: 497px;
  background: rgba(255, 255, 255, 0.13);
  backdrop-filter: blur(7px);
  outline: none;
  border-radius: 20px;
  font-family: 'Proxima Nova';
  font-style: normal;

  .mainLabel {
    font-weight: 800;
    font-size: 60px;
    line-height: 110%;
    color: #FFFFFF;
    margin-bottom: 12px;
    margin-top: 80px;
  }
  .subLabel {
    font-weight: 400;
    font-size: 24px;
    line-height: 120%;
    text-align: center;
    color: #FFFFFF;
    opacity: 0.4;
    margin: 0;
  }

  .dateSelector {
    display: flex;
    margin: 40px;
    .customDatePiker {
      margin: 8px;
    }
  }

  .closeIconContainer {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 70px;
    min-height: 64px;
    cursor: pointer;
    .closeIcon {
      position: absolute;
      top: 30px;
      right: 30px;
    }
  }

  .downloadButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 137px;
    height: 55px;
    background: #7F56D9;
    border-radius: 12px;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.2px;
    margin-bottom: 16px;
    cursor: pointer;
  }
  .cancelButton {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: -0.2px;
    color: #7F56D9;
    cursor: pointer;
    width: 137px;
    height: 55px;
  }
}
.modalPopup {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(10px);
}