@import "../../style/colors";

.container {
  position: absolute;
  width: 100%;
  min-height: 100vh;
  top: 0;
  padding-top: 80px;
  box-sizing: border-box;
}

.body {
  width: 100%;
  height: 100%;
  padding: 20px 40px 40px 40px;
  box-sizing: border-box;
  display: flex;
  position: relative;
  background-color: #F4F5F8;
}

.bodyWhenWarning {
  padding-top: 5px;
}

.board {
  width: 100%;
  margin-right: 40px;
}
.photoContainer {
  max-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.imageColumn {
  padding-top: 158px;
  max-width: 500px;
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.barLoader {
  width: 463px;
}
.fullBarLoader {
  width: 100%;
}

.uploadCont {
  height: 100%;
  border: 2px dashed rgba(0, 0, 0, .6);
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: rgba(0, 0, 0, .6);
  position: relative;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, .4);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity ease 100ms;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
}

.remove {
  width: 50px;
  height: 50px;
  color: #3D3D3D;
}

.browse {
  color: $primary;
  cursor: pointer;
}

.warningContainer {
  width: 100%;
  background-color: #F4F5F8;
  padding-top: 20px;
  display: flex;
  justify-content: center;
}

.kycWarning {
  padding: 10px;
  width: fit-content;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 700;
  color: $danger;
  align-content: center;
  border: 3px solid $danger;
  border-radius: 15px;
  background: $white;

  svg {
    width: 54px;
    height: 54px;
    align-self: center;
    padding-bottom: 10px;
  }

  .link {
    color: $primary;
  }
}

.warningWord {
  font-weight: 800;
}

.panel {
  display: flex;
  flex-direction: column;
  flex-grow: 3;
  position: relative;
  box-sizing: border-box;
  max-width: 806px;
  padding-left: 40px;
  padding-right: 40px;
}

.panelInputs {
  display: flex;
  flex-direction: column;
}

.panelLeft,
.panelRight {
  flex: 1;
  margin-right: 30px;
}

.panelLeft {
  margin-right: 30px;
}

.input {
  width: 100%;
  border-radius: 5px;
  background-color: #F6F6F6;
  padding: 0 22px 12px;
  margin-bottom: 20px;
}

.inputLabel {
  left: 22px;
}

.select {
  width: 100% !important;
  outline: none !important;
  height: 50px !important;
  border-radius: 10px !important;
  box-sizing: border-box !important;
  font-size: 16px !important;
  padding-left: 12px !important;
  cursor: pointer !important;
  background-color: #F4F5F8;
}

.collection {
  display: flex;
  align-items: center;
  padding: 5px 10px;
}

.collectionLogo {
  width: 34px;
  height: 34px;
  border-radius: 50%;
}

.collectionName {
  margin-left: 12px;
  font-weight: 500;
  font-size: 16px;
  color: #3D3D3D;
}

.button {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  width: 112px;
  height: 55px;
  color: #FFF;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #7F56D9;
  border-radius: 12px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;

  &:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
  }
}
.cancelButton {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  width: 112px;
  height: 55px;
  color: #7F56D9;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #FFF;
  // border: 2px solid #7F56D9;
  border-radius: 12px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;

  &:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
  }
}
.fee {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3D3D3D;
  opacity: 0.6;
}

.mintStatusContainer {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: x-large;
  width: 100%;
}

.tnxAnchor {
  text-decoration: unset;
  font-size: 18px;
  margin-top: 18px;
  color: $primary;
}

.formGroup {
  margin-top: 25px;
  position: relative;
}

.formLabel {
  margin: 0 0 8px;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #121223;
  opacity: 0.5;
}

.formInput {
  width: 100%;
  outline: none;
  height: 50px;
  border-radius: 10px;
  border: 0;
  padding: 8px 16px;
  box-sizing: border-box;
  font-size: 16px;
  background-color: #F4F5F8;
}

.dollarInput {
  position: relative;
  input {
    padding-left:27px;
  }
}

.dollarInput::before{
  position: absolute;
  top: 0;
  content:"$";
  left: 15px;
}

.longInput {
  resize: none;
  height: 100px;
  margin: 0;
}

.lengthIndicator {
  margin-top: 6px;
  margin-right: 6px;
  text-align: right;
  color: rgba(0, 0, 0, .6);
}

.disabled {
  cursor: not-allowed;
  box-shadow: none;
  opacity: 0.7;
}

:global {
  .tui-image-editor {
    border: 1px solid rgba(0, 0, 0, .2);
  }
}

.warning {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 150px;
}

.warningIcon {
  width: 100px !important;
  height: 100px !important;
  color: $danger;
}

.warningTitle {
  margin-top: 20px;
  font-weight: 500;
  font-size: 24px;
  text-align: center;
  color: #3D3D3D;
}

.switchButton {
  margin-top: 40px;
  width: 160px;
  height: 40px;
  border-radius: 8px;
  font-weight: 700;
  font-size: 18px;
  border: 1px solid $primary;
  color: $primary;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.errorValidate {
  box-shadow: 0 0 10px 1px rgba(236, 28, 28, 0.76);
}

@media only screen and (max-width: 1150px) {
  .body {
    flex-direction: column-reverse;
    padding-top: 40px;
    height: fit-content;
  }

  .board {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    padding-top: 133px;
  }

  .body {
    padding: 40px 20px;
  }

  .panelInputs {
    width: 100%;
    flex-direction: column;
  }

  .panelLeft {
    margin-right: 0;
  }
}

.verifiedKys{
  pointer-events:none;
  background-color: gray;
}
.createLogo {
  font-style: normal;
  font-weight: 800;
  font-size: 60px;
  line-height: 110%;
  color: #121223;
  margin-bottom: 20px;
  margin-left: 20px;
}

.createSubtitles {
  text-align: justify;
  margin-left: 20px;
  font-weight: 500;
}
.currentFeePrice {
  font-weight: 800;
}

.inputGroup {
  box-sizing: border-box;
  width: 100%;
  padding: 28px 40px 40px 40px;
  margin: 10px;
  background-color: #FFF;
  border-radius: 8px;
}

.additionalLabel {
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  color: #121223;
  margin: 0;
}

.documentsPanel {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px;
  margin-top: 10px;
}

.inputDocument {
  width: 202px;
  min-height: 202px;
}

.smallLabel {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: #121223;
  margin-top: 0;
}

.mediumLogo {
  width: 250px;
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 110%;
  margin-top: 52px;
  margin-bottom: 16px;
}

.imageHolder {
  width: 434px;
  height: 439px;
  background-color: #FFFFFF;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  .photo {
    width: 250px;
    height: 160px;
    object-fit: cover;
    background-color: #F4F5F8;
  }
  .photoSceleton {
    width: 250px;
    height: 237px;
    box-shadow: 0px 20px 80px rgba(18, 18, 35, 0.08);
  }
  .imgSceleton {
    width: 250px;
    height: 160px;
  }
}
.documentsList {
  display: flex;
  flex-wrap: wrap;
}
.deleteImage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 240px;
  height: 150px;
  border-radius: 8px;
  img {
    width: 240px;
    height: 150px;
    object-fit: cover;
    border-radius: inherit;
    cursor: unset;
  }
  .deleteIcon {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;  
    width: 240px;
    height: 150px;
    background-color: red;
    color: #FFF;
    z-index: 5;
    opacity: 0;
    border-radius: inherit;
    cursor: pointer;
  }
  .deleteIcon:hover {
    opacity: 0.5;
    cursor: pointer;
  }
  .smallIcon {
    width: 202px;
    height: 126px;
  }
}
.smallImage {
  width: 202px;
  height: 126px;
}
.addPhotoButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #F4F5F8;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
  }

  .uploadsubtitle {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    color: #121223;
    margin: 5px;
    opacity: 0.5;
  }
  
  .iconSubtitle {
    margin: 5px;
    opacity: 0.5;
    font-size: 60px;
    font-weight: 100;
  }
}
.menuLabel {
  opacity: 0.5;
}

.infoLabel {
  margin-top: 20px;
  margin-bottom: 0;
  color: red;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.docsLink {
  color: dodgerblue;
}

.linkUnderline {
  &:hover {
    text-decoration: underline;
    opacity: 0.9;
  }
}